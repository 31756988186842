import React from 'react';
import TelaNoticias from './pages/Noticias/TelaNoticias';
import TelaVisualizacao from './pages/TelaVisualização/TelaVisualizaçao';
import PesquisaAvancada from "./pages/PesquisaAvançada/PesquisaAvancada";
import TelaDadosUsuario from "./pages/TelaDadosUsuario/TelaDadosUsuario";
import EditarClipping from "./pages/EditarClipping/EditarClipping";
import TelaError from "./pages/TelaError/404";
import CadastrarNoticia from "./pages/CadastrarNoticia/CadastrarNoticia";
import VisualizarNoticiaNewsletter from "./pages/TelaVisualizaçãoPublica/TelaVisualizacaoPublica";
import withAuth from "./services/withAuth"
import Telarelatorio from "./pages/TelaRelatorio/TelaRelatorio"
import Newsletter from './pages/Newsletter/Newsletter';
import { useParams, useLocation } from 'react-router-dom';


import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ClippingsProvider } from './services/ClippingsContext';
import '../src/app.css';

function PublicRoutes() {
  return (
    <Switch>
      {/* Rota pública */}
      <Route path="/visualizarNoticiaNewsletter/:clippingId" component={VisualizarNoticiaNewsletter} />
      {/* Redireciona para a página de erro se a rota não for encontrada */}
      <Route path="*" component={TelaError} />
    </Switch>
  );
}

function PrivateRoutes() {
  return (
    <ClippingsProvider>
      <Switch>
        {/* Redireciona a raiz para /:clientId/telaNoticias */}
        <Route path="/" exact>
          <Redirect to="/:clientId/telaNoticias" /> {/* Substitua 'clientId' pelo clientId padrão que desejar */}
        </Route>
        <Route path="/:clientId/telaNoticias" exact component={withAuth(TelaNoticias)} />
        <Route path="/:clientId/visualizacao/:clippingId" component={withAuth(TelaVisualizacao)} />
        <Route path="/:clientId/pesquisaAvancada" exact component={withAuth(PesquisaAvancada)} />
        <Route path="/:clientId/teladadosusuario" exact component={withAuth(TelaDadosUsuario)} />
        <Route path="/:clientId/editarClipping" exact component={withAuth(EditarClipping)} />
        <Route path="/:clientId/cadastrarnoticia" exact component={withAuth(CadastrarNoticia)} />
        <Route path="/:clientId/telarelatorio" exact component={withAuth(Telarelatorio)} />
        <Route path="/:clientId/newsletter" exact component={withAuth(Newsletter)} />
        
        {/* Rota para a página de erro */}
        <Route path="/404" component={TelaError} />
        
        {/* Redireciona para /telaerror se o caminho não corresponder a nenhuma rota */}
        <Redirect to="/404" />
      </Switch>
    </ClippingsProvider>
  );
}

function TelaTodos() {
  return (
    <Router>
      <Switch>
        {/* Define as rotas públicas */}
        <Route path="/visualizarNoticiaNewsletter/:clippingId" component={PublicRoutes} />

        {/* Define as rotas privadas */}
        <Route component={PrivateRoutes} />
      </Switch>
    </Router>
  );
}

export default TelaTodos;
