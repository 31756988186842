import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import MediaTypesChart from "../../../Charts/MediaTypesChart/MediaTypesChart";
import ReactLoading from 'react-loading';
import "./ReportStatistic.css"; // Estilos do relatório

const ReportStatistic = ({ startDate, endDate, data}) => {
    const [title, setTitle] = useState("Estatísticas do Clipping");
    const [isExporting, setIsExporting] = useState(false);
    const [exportSuccessMessage, setExportSuccessMessage] = useState("");
    const brandImage = data.brand_newsletter
    ? data.brand_newsletter.replace(
        process.env.REACT_APP_AWS_URL,
        process.env.REACT_APP_CLIENT_URL
      )
    : `${process.env.REACT_APP_CLIENT_URL}/media/banner-padrao-newsletter.png`;
    const totalClippings = data.total_clippings || 0;
    const totalMonitoredVehicles = data["total_monitored_vehicles "] || data.total_monitored_vehicles || 0;
    

    // Se os dados ainda não existirem, mostrar uma mensagem
    if (!data) {
        return <p>Gerar relatório para visualizar os dados.</p>;
    }

    const exportToPDF = () => {
        setIsExporting(true);
        setExportSuccessMessage("");
    
        document.querySelectorAll(".page-break-statistic").forEach(el => {
            el.classList.add("pdf-hidden");
        });
    
        const input = document.getElementById("relatorio-estatistico");
    
        html2canvas(input, { scale: 1.5, useCORS: true }).then(canvas => {
            const imgData = canvas.toDataURL("image/jpeg", 0.8);
            const pdf = new jsPDF("p", "mm", "a4");
    
            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
    
            let heightLeft = imgHeight;
            let position = 0;
    
            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
    
            while (heightLeft > 0) {
                position -= pageHeight;
                pdf.addPage();
                pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
    
            pdf.save("relatorio_estatistico.pdf");
    
            document.querySelectorAll(".page-break-statistic").forEach(el => {
                el.classList.remove("pdf-hidden");
            });
    
            setIsExporting(false);
            setExportSuccessMessage("PDF exportado com sucesso!");
    
            setTimeout(() => setExportSuccessMessage(""), 5000);
        }).catch(() => {
            setIsExporting(false);
            setExportSuccessMessage("Erro ao exportar PDF. Tente novamente.");
        });
    };
    
    return (
        <div id="relatorio-estatistico" className="relatorio-estatistico-container">
            <div className="report-cover-statistic">
                {brandImage && <img src={brandImage} alt="Marca" className="brand-logo-statistics" />}
                <h1 contentEditable="true" onBlur={(e) => setTitle(e.target.innerText)}>
                    {title}
                </h1>
                <p> {startDate?.toLocaleDateString()} - {endDate?.toLocaleDateString()}</p>
            </div>

            <div className="page-break-statistic"></div>

            <div className="report-content-statistic">

            <div className="clipping-info-statistics">
                <h3>Notícias selecionadas para o clipping:</h3>
                <ul>
                    <li>
                        <strong>De {startDate?.toLocaleDateString()} a {endDate?.toLocaleDateString()}:</strong> {totalClippings}
                    </li>
                    <li>
                        <strong>Total de veículos monitorados:</strong> {totalMonitoredVehicles}
                    </li>
                </ul>
            </div>
            <div className="media-types-container-statistics">
                <table className="relatorio-estatistico-table">
                    <thead>
                        <tr>
                            <th>Tipo de Mídia</th>
                            <th>Quantidade</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(data.media_types).length > 0 ? (
                            Object.entries(data.media_types).map(([key, value], index) => (
                                <tr key={index}>
                                    <td>{key.toUpperCase()}</td>
                                    <td>{value}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="2">Nenhum dado disponível</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="media-chart-statistics">
                <MediaTypesChart mediaTypes={data.media_types} />
            </div>
            </div>
            <button onClick={exportToPDF}  className="download-button-statistics">Baixar PDF</button>

            {exportSuccessMessage && (
                <div className={`message-box ${exportSuccessMessage.includes("Erro") ? "error-message" : "success-message"}`}>
                    <p>{exportSuccessMessage}</p>
                </div>
            )}

            {isExporting && (
                <div className="loading-overlay active">
                    <ReactLoading type={'spin'} color={'#121926'} height={80} width={80} />
                </div>
            )}
        </div>
        
    );
};

export default ReportStatistic;
