import React, { useState } from 'react';
import { FaCopy, FaWhatsapp, FaTimes } from 'react-icons/fa';
import './ShareModal.css';

const ShareModal = ({ link, onClose }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }).catch(err => console.error('Erro ao copiar o link:', err));
  };

  const handleShareWhatsApp = () => {
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(link)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="modal-overlay-share-modal">
      <div className="modal-content-share-modal">
        <div className="title-container-share-modal">
          <h2 className='title-share-modal'>Compartilhar</h2>
          <button className="close-modal-share-modal" onClick={onClose}><FaTimes /></button>
        </div>
        <div className="share-options-share-modal">
          <button className="share-button-share-modal" onClick={handleCopyLink}>
            <FaCopy className="icon-share-modal" />
            <span>{copied ? 'Copiado!' : 'Copiar Link'}</span>
          </button>
          <button className="share-button-share-modal" onClick={handleShareWhatsApp}>
            <FaWhatsapp className="icon-share-modal" />
            <span>WhatsApp</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
