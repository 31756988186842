import { post, del, get } from './generic.api';

export const sendNewsletter = async ({ clientId, newsletterId, startDate, endDate, startTime, endTime, email }) => {
    try {
        const payload = {
            client: clientId,
            newsletter: newsletterId,
        };

        if (startDate) payload.date_start = startDate;
        if (endDate) payload.date_end = endDate;
        if (startTime) payload.time_start = startTime;
        if (endTime) payload.time_end = endTime;
        if (email) payload.email = email;

        const response = await post(`/preview-newsletter/send/`, payload, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        console.log("Newsletter enviada com sucesso!", response.data);
        return response.data;
    } catch (error) {
        console.error("Erro ao enviar a newsletter:", error.response ? error.response.data : error.message);
        throw error;
    }
};

export const sendNewsletterWithClippings = async ({ clientId, email, clippings, newsletter }) => {
    try {
        const payload = {
            client: clientId,
            email,
            clippings: clippings.length > 0 ? clippings : [],
            newsletter,
        };

        const response = await post(`/preview-newsletter/send/`, payload, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        console.log("Newsletter enviada com sucesso!", response.data);
        return response.data;
    } catch (error) {
        console.error("Erro ao enviar a newsletter:", error.response ? error.response.data : error.message);
        throw error;
    }
};

  