import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import "./Menu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faUser, faCog, faBars } from "@fortawesome/free-solid-svg-icons";
import { fetchUser } from "../../services/api/fetchUser";
import { fetchClientData } from "../../services/api/fetchClient";
import LoadingBar from 'react-top-loading-bar';
import newsletterIcon from "../../assets/report-icons/icon-menu-newsletter.svg";

function Menu({ onToggleMenu }) {
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);
  const [clientData, setClientData] = useState({ brand: '' });
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { clientId } = useParams();
  const [userProfile, setUserProfile] = useState(null);

  const getMenuItemIndex = () => {
    switch (location.pathname) {
      case `/${clientId}/telaNoticias`:
        return 0;
      case `/${clientId}/pesquisaAvancada`:
        return 1;
       case `/${clientId}/telarelatorio`:
         return 2;
       case `/${clientId}/newsletter`:
         return 3;
       case `/${clientId}/cadastrarnoticia`:
         return 4;
       case `/${clientId}/teladadosusuario`:
         return 5;
       case `/${clientId}/sair`:
         return 6;
       default:
         return 0;
    }
  };

  const [activeMenuItem, setActiveMenuItem] = useState(getMenuItemIndex());

  useEffect(() => {
    setActiveMenuItem(getMenuItemIndex());
  }, [location, clientId]);

  const toggleMenu = () => {
    const newExpandedState = !expanded;
    setExpanded(newExpandedState);
    onToggleMenu(newExpandedState);
  };

  useEffect(() => {
    const getClientData = async () => {
      try {
        setLoadingProgress(30);
        const client = await fetchClientData(clientId);
        setClientData(client);

        // Busque o perfil do usuário
        const user = await fetchUser(clientId);
        setUserProfile(user.profile);

        setLoadingProgress(100);
      } catch (error) {
        console.error("Error fetching client data:", error);
        setLoadingProgress(100);
      } finally {
        setIsLoading(false);
      }
    };

    getClientData();
  }, [clientId]);

  const clearSelectedLocalStorageItems = () => {
    const keysToRemove = [
      'searchDates',
      'selectedFilter',
      'token',
      'selectedFilter',
      'selectedSort',
      'selectedEvaluate',
      'categoriasSelecionadas',
    ];
  
    keysToRemove.forEach((key) => localStorage.removeItem(key));
  };

  const handleLogout = (event) => {
    event.preventDefault();
    clearSelectedLocalStorageItems();

    if (userProfile === 'cliente' || userProfile === 'cliente_admin') {
      window.location.href = `${process.env.REACT_APP_LINK_URL}seguranca/sair/`;
    } else {
      window.location.href = process.env.REACT_APP_LINK_URL;
    }
  };

  const handleAdministracao = () => {
    window.location.href = `${process.env.REACT_APP_LINK_URL}pt-br/clientes/admin/${clientId}/visualizar/`;
  };

  return (
    <>
      <LoadingBar
        color='#007bff'  // Ajuste a cor aqui para azul
        progress={loadingProgress}
        onLoaderFinished={() => setLoadingProgress(0)}
      />
      {!isLoading ? (
        <>
          <div className={`container ${expanded ? "expanded" : ""}`}>
            <div className="profile">
              <div className="avatar-wrapper">
                <img
                  src={clientData.brand}
                  alt="User avatar"
                  className="avatar"
                />
              </div>
              {/* <div className="profile-info">
                <div className="username">{userData.client.slug}</div>
                <a href="#" className="edit-link">
                  Editar
                </a>
              </div> */}
            </div>

            <div
              className={`menu-item ${activeMenuItem === 0 ? "active" : ""}`}
              onClick={() => setActiveMenuItem(0)}
            >
              <a href={'/'+ clientId + '/telaNoticias'} className="menu-item-content"> {/* Substituído div por a (âncora) */}
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/6a879e978331663227b5fd0041491dc69ae731bc4be45c911e710938ac9f4e6c?apiKey=c7cb0dec5bf7436eb48ab9b2fb417d9d&"
                  alt="News icon"
                  className="menu-icon"
                />
                <div className="menu-label">Notícias</div>
              </a>
            </div>

            <div
              className={`menu-item ${activeMenuItem === 1 ? "active" : ""}`}
              onClick={() => setActiveMenuItem(1)}
            >
              <a href={'/'+ clientId + '/pesquisaAvancada'} className="menu-item-content">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/8f0b385cd15ac5b3e7421d37125e05034d009631418ca2d18069aaa4964eeecf?apiKey=c7cb0dec5bf7436eb48ab9b2fb417d9d&"
                  alt="News icon"
                  className="menu-icon"
                />
                <div className="menu-label">Pesquisa avançada</div>
              </a>
            </div>
 
            {<div
              className={`menu-item ${activeMenuItem === 2 ? "active" : ""}`}
              onClick={() => setActiveMenuItem(2)}
            >
              <a href={'/'+ clientId + '/telarelatorio'} className="menu-item-content">
                <img
                  src="https://cdn-icons-png.flaticon.com/128/4486/4486185.png"
                  alt="News icon"
                  className="menu-icon"
                />
                <div className="menu-label">Relatórios</div>
              </a>
            </div> }

            <div
              className={`menu-item ${activeMenuItem === 3 ? "active" : ""}`}
              onClick={() => setActiveMenuItem(3)}
            >
              <a href={'/'+ clientId + '/newsletter'} className="menu-item-content">
                <img
                  src={newsletterIcon}
                  alt="Newsletter icon"
                  className="menu-icon"
                />
                <div className="menu-label">Newsletter</div>
              </a>
            </div>

            {/* // Dentro do return, condicionalmente renderiza o botão "Cadastrar Notícia" */}
            {userProfile !== 'cliente' && (
              <div
                className={`menu-item ${activeMenuItem === 4? "active" : ""}`}
                onClick={() => setActiveMenuItem(4)}
              >
                <a href={'/'+ clientId + '/cadastrarnoticia'} className="menu-item-content">
                  <img
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/8520bbfa57c9131ac4cc4f9fa70c0cad1a618ceefd10e053ffac788d588152d1?apiKey=c7cb0dec5bf7436eb48ab9b2fb417d9d&"
                    alt="News icon"
                    className="menu-icon"
                  />
                  <div className="menu-label">Cadastrar Notícia</div>
                </a>
              </div>
            )}
            {(userProfile === 'administrador' || userProfile === 'funcionario') && (
            <div
              className={`menu-item ${activeMenuItem === 5 ? "active" : ""}`}
              onClick={() => setActiveMenuItem(5)}
            >
              <a onClick={handleAdministracao} className="menu-item-content">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f58b5e5838e61062f9ea99552b64fe499aa7841ae0ca4075fe93a524303e6e10?apiKey=c7cb0dec5bf7436eb48ab9b2fb417d9d&"
                  alt="News icon"
                  className="menu-icon"
                />
                <div className="menu-label">Administração</div>
              </a>
            </div>
            )}

            <div
              className={`menu-item ${activeMenuItem === 6 ? "active" : ""}`}
              onClick={() => setActiveMenuItem(6)}
            >
              <a onClick={handleLogout} className="menu-item-content">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/779751b9a339222f467afe3a1f424378925ad6f398411c6ad40d624e9e4e530a?apiKey=c7cb0dec5bf7436eb48ab9b2fb417d9d&"
                  alt="News icon"
                  className="menu-icon"
                />
                <div className="menu-label">Sair</div>
              </a>
            </div>
          </div>

          <div
            className="buttonExpandedMenu"
            style={{ marginLeft: expanded ? "215px" : "64px" }}
            onClick={toggleMenu}
          >
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/12c4b6aad0d277649e95831a7b0295e510866b467769fe61d2193214a69772b8?apiKey=c7cb0dec5bf7436eb48ab9b2fb417d9d"
              alt={expanded ? "Retrair" : "Expandir"}
            />
          </div>
        </>
      ) : (
        <div className="loading-container">
          <div className="loading-bar">
            <LoadingBar
              color='#004080'
              progress={loadingProgress}
              onLoaderFinished={() => setLoadingProgress(0)}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Menu;
