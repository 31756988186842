import React, { useMemo } from 'react';
import './WordCloud.css';
import ReactWordcloud from 'react-wordcloud';

 export function WordCloud({words = []}) {
    const callbacks = {
        getWordColor: (word) => word.color,
        onWordClick: console.log,
        onWordMouseOver: console.log,
      }
      const options = {
        rotations: 2,
        rotationAngles: [-270, 0],
        fontWeight:"bold",
        fontSizes: [10, 30],
        enableTooltip: true,
        deterministic: true,
      };
      const size = [550, 500];

      const filteredWords = useMemo(() => {
        return words.filter(word => word.value >= 3);
    }, [words]);
  
    return (
        <ReactWordcloud
        callbacks={callbacks}
        options={options}
        size={size}
        words={filteredWords}
      />
    );
  }
  
  export default WordCloud;