import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export function MediaTypesChart({ mediaTypes, innerRadius = 60, width = 800, height = 400, fontSize = 12, hideLabel = false }) {
    
    if (!mediaTypes || Object.keys(mediaTypes).length === 0) {
        return <p>Sem dados para exibir</p>;
    }

    const COLORS = ["#FF5733", "#33FF57", "#3380FF", "#FF33A8", "#FFB533", "#9D33FF"];

    const dataPoints = Object.entries(mediaTypes).map(([key, value], index) => ({
        label: key.toUpperCase(),
        y: value,
        color: COLORS[index % COLORS.length]
    }));

    const options = {
        width,
        height,
        backgroundColor: "transparent",
        animationEnabled: false,
        toolTip: {
            enabled: true,
            animationEnabled: true,
        },
        data: [{
            type: "doughnut",
            startAngle: 270,
            innerRadius,
            indexLabelFontSize: fontSize,
            indexLabel: hideLabel ? "" : "{label} - #percent%",
            dataPoints: dataPoints
        }],
    };

    return <CanvasJSChart options={options} />;
}

export default MediaTypesChart;
