import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './ChangeCategoryModal.css';
import { fetchCategories } from '../../services/api/fetchCategories';
import  { updateClippingCategory }  from '../../services/api/fetchClipping';
import ReactLoading from 'react-loading';

const ChangeCategoryModal = ({ clientId, selectedCards, onClose, onSuccessMessage, onErrorMessage, setShouldRefresh }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [actionType, setActionType] = useState(null);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: '10px',
      fontSize: '12px',
      borderColor: state.isFocused ? '#ccc' : '#ccc',
      borderRadius: '4px',
      backgroundColor: 'white',
      color: '#333',
      outline: 'none',
      boxShadow: state.isFocused ? '0 0 0 1px #ccc' : 'none',
      transition: 'border-color 0.3s, box-shadow 0.3s',
      '&:hover': {
        borderColor: '#ccc',
      },
      maxHeight: '150px', 
      overflowY: 'auto', 
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#eee' : '#fff',
      color: '#333',
      padding: '10px',
      '&:active': {
        backgroundColor: '#eee',
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 2,
    }),
  };

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const data = await fetchCategories(clientId);
        setCategories(data.map(cat => ({ value: cat.id, label: cat.name })));
      } catch (error) {
        console.error("Erro ao buscar categorias:", error);
      }
    };
    loadCategories();
  }, [clientId]);

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
  };

  const handleSaveCategory = async () => {
    if (!actionType) {
      onErrorMessage("Por favor, selecione uma ação.");
      return;
    }
    if (selectedCategory.length === 0) {
      onErrorMessage("Por favor, selecione pelo menos uma categoria.");
      return;
    }
  
    setIsLoading(true);
    try {
      await updateClippingCategory(selectedCards, selectedCategory.map(cat => cat.value), actionType, clientId);
      onSuccessMessage(`Categorias ${actionType === 'add' ? 'adicionadas' : 'removidas'} com sucesso!`);
      setShouldRefresh(true);
      onClose();
    } catch (error) {
      onErrorMessage("Erro ao alterar categorias.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="modal-overlay-change-category">
      <div className="modal-content-change-category">
        <h2 className='modal-title-change-category'>Alterar Categoria</h2>

        <div className='container-change-category'>

            <div className="filter-container-change-category">
                <div className="filter-label-change-category">Escolha a ação:</div>
                <Select
                    className="filter-select-change-category"
                    options={[
                        { value: 'add', label: 'Adicionar Categoria' },
                        { value: 'remove', label: 'Remover Categoria' }
                    ]}
                    value={actionType ? { value: actionType, label: actionType === 'add' ? 'Adicionar Categoria' : 'Remover Categoria' } : null}
                    onChange={(selectedOption) => setActionType(selectedOption.value)}
                    placeholder="Selecione a ação"
                    styles={customStyles}
                    />
            </div>

            {actionType && (
            <div className="filter-container-change-category">
                <div className="filter-label-change-category">Categorias:</div>
                <Select
                    className="filter-select-change-category"
                    options={categories}
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    placeholder="Selecione as categorias"
                    styles={customStyles}
                    isMulti
                    />
            </div>
            )}
        </div>

        <div className="modal-actions-change-category">
          <button onClick={onClose} className="cancel-button-change-category">Cancelar</button>
          <button onClick={handleSaveCategory} className="save-button-change-category">
            Alterar Categoria
          </button>
        </div>
      </div>

      {isLoading && (
        <div className={isLoading ? 'loading-overlay active' : 'loading-overlay'}>
          <ReactLoading type={'spin'} color={'#121926'} height={80} width={80} />
        </div>
      )}
    </div>
  );
};

export default ChangeCategoryModal;
