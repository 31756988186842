import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Menu from '../../components/menu/Menu';
import NavBar from '../../components/navbar/NavBar';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import { validateEmail } from '../../utils/mail';
import ReactLoading from 'react-loading';
import { fetchClientNewsletterPreview } from '../../services/api/fetchClient';
import { sendNewsletter } from '../../services/api/fetchNewsletter';
import 'react-datepicker/dist/react-datepicker.css';
import './Newsletter.css';

const Newsletter = () => {
    const { clientId } = useParams();
    const [isMenuExpanded, setIsMenuExpanded] = useState(false);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [email, setEmail] = useState('');
    const [showEmailAndSendButton, setShowEmailAndSendButton] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [newsletterId, setNewsletterId] = useState(null);
    const [template, setTemplate] = useState('');

    const handleToggleMenu = (expanded) => {
        setIsMenuExpanded(expanded);
    };

    const handleGenerate = async () => {
        setErrorMessage('');
        setSuccessMessage('');
        setIsLoading(true);
        setShowEmailAndSendButton(false);
        setNewsletterId(null);

        if (!startDate || !endDate) {
            setErrorMessage('Por favor, selecione uma data inicial e final.');
            setTimeout(() => setErrorMessage(''), 5000);
            setIsLoading(false);
            return;
        }
    
        try {
            const response = await fetchClientNewsletterPreview(clientId);
            
            if (response.errorTrue) {
                setErrorMessage('Nenhuma newsletter está cadastrada para ser gerada.');
                setIsLoading(false);
                return;
            }
    
            if (!response.newsletter_id) {
                setErrorMessage('Erro: ID da newsletter não encontrado.');
                setIsLoading(false);
                return;
            }
    
            const newsletterId = response.newsletter_id;
            setNewsletterId(newsletterId);
            setTemplate(response.template);
    
            const formattedStartDate = startDate ? startDate.toLocaleDateString('pt-BR') : "";
            const formattedEndDate = endDate ? endDate.toLocaleDateString('pt-BR') : "";
            const formattedStartTime = startTime || ""; 
            const formattedEndTime = endTime || "";             

            console.log("ID da newsletter:", newsletterId);
            console.log("Data de inicio:", formattedStartDate);
            console.log("Data de fim:", formattedEndDate);
            console.log("Hora de inicio:", formattedStartTime);
            console.log("Hora de fim:", formattedEndTime);
    
            const url = `${process.env.REACT_APP_LINK_URL}pt-br/newsletter/newsletter/${clientId}/${newsletterId}/preview/publico/?start_date=${encodeURIComponent(formattedStartDate)}&end_date=${encodeURIComponent(formattedEndDate)}&start_time=${encodeURIComponent(formattedStartTime)}&end_time=${encodeURIComponent(formattedEndTime)}`;

            console.log("URL da newsletter:", url);
    
            window.open(url, '_blank');
            setSuccessMessage("Newsletter gerada com sucesso!");
            setShowEmailAndSendButton(true);
    
            setTimeout(() => setSuccessMessage(''), 5000);
        } catch (error) {
            setErrorMessage('Erro ao buscar a newsletter. Tente novamente mais tarde.');
            console.error("Erro na requisição:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const formatDate = (date) => {
        if (!date) return undefined;
        return new Date(date).toLocaleDateString('pt-BR');
    };
    

    const handleSendNewsletter = async () => {
        if (!validateEmail(email)) {
            setErrorMessage('Por favor, insira um e-mail válido para envio.');
            setTimeout(() => setErrorMessage(''), 5000);
            return;
        }
    
        if (!newsletterId) {
            setErrorMessage('Erro: Nenhuma Newsletter foi gerada para envio.');
            return;
        }
    
        setIsLoading(true);
    
        try {
            const formattedStartDate = startDate ? startDate.toISOString().split('T')[0] : undefined;
            const formattedEndDate = endDate ? endDate.toISOString().split('T')[0] : undefined;
            const formattedStartTime = startTime || undefined;
            const formattedEndTime = endTime || undefined;

            console.log(newsletterId)
    
            await sendNewsletter({
                clientId,
                newsletterId,
                startDate: formatDate(formattedStartDate),
                endDate: formatDate(formattedEndDate),
                startTime: formattedStartTime,
                endTime: formattedEndTime,
                email,
            });
    
            setSuccessMessage('Newsletter enviada com sucesso!');
    
            setEmail('');
    
            setTimeout(() => setSuccessMessage(''), 5000);
        } catch (error) {
            setErrorMessage('Erro ao enviar a newsletter. Tente novamente mais tarde.');
            setTimeout(() => setErrorMessage(''), 5000);
            console.error("Erro ao enviar newsletter:", error);
        } finally {
            setIsLoading(false);
        }
    };
    
    return (
        <div className={`container-tela-newsletter ${isMenuExpanded ? 'menu-expanded' : 'menu-collapsed'}`}>
            <div className="menu-tela-newsletter">
                <Menu onToggleMenu={handleToggleMenu} />
            </div>
            <div className="navbar-tela-newsletter">
                <NavBar />
            </div>

            <div className="main-tela-newsletter">
                <h1>Newsletter</h1>

                <div className="separator-tela-newsletter">
                    <div className="date-picker-tela-newsletter">
                        <div className="date-picker-label-tela-newsletter">Datas</div>
                        <div className="date-picker-header-tela-newsletter">
                            <div className="date-picker-content-tela-newsletter">
                                <DatePicker
                                    selectsRange
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                    className="selected-date-input"
                                    placeholderText='DD/MM/AAAA - DD/MM/AAAA'
                                />
                            </div>
                            <img
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/6c6cd33072230ae936f1e816d9aa1ba45453ba4d5a4f9099598c1801929589e2"
                                alt="Calendário"
                                className="calendar-icon-tela-newsletter"
                                onClick={() => document.getElementsByClassName('selected-date-input')[0].click()}
                            />
                        </div>
                    </div>

                    <div className="time-picker-tela-newsletter">
                        <div className="time-picker-label-tela-newsletter">Horário Inicial</div>
                        <InputMask
                            mask="99:99"
                            maskChar={null}
                            value={startTime || ''}
                            onChange={(e) => setStartTime(e.target.value)}
                            placeholder="HH:MM"
                            className="selected-time-input-newsletter"
                        />
                    </div>

                    <div className="time-picker-tela-newsletter">
                        <div className="time-picker-label-tela-newsletter">Horário Final</div>
                        <InputMask
                            mask="99:99"
                            maskChar={null}
                            value={endTime || ''}
                            onChange={(e) => setEndTime(e.target.value)}
                            placeholder="HH:MM"
                            className="selected-time-input-newsletter"
                        />
                    </div>
                </div>

                <div className="generate-newsletter-button-container">
                    <button onClick={handleGenerate} className="buttonGenerateNewsletter">
                        Gerar
                    </button>
                </div>
            </div>

            {showEmailAndSendButton && !template.endsWith('newsletter-wpp.html') && (
                <div className="email-send-container-newsletter">
                    <div className="input-field-tela-newsletter-mail">
                        <div className="input-label-tela-newsletter-mail">E-mail para envio</div>
                        <input
                            type="email"
                            className="input-text-tela-newsletter"
                            placeholder="Digite o e-mail para envio"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="send-newsletter-button-container">
                        <button onClick={handleSendNewsletter} className="buttonSendNewsletter">
                            Enviar
                        </button>
                    </div>
                </div>
            )}

            {successMessage && (
                <div className="success-message">
                    <p>{successMessage}</p>
                </div>
            )}
            {errorMessage && (
                <div className="error-message">
                    <p>{errorMessage}</p>
                </div>
            )}

            {isLoading && (
                <div className="loading-overlay active">
                    <ReactLoading type={'spin'} color={'#121926'} height={80} width={80} />
                </div>
            )}

        </div>
    );
};

export default Newsletter;
