import React, { useState, useEffect, useMemo } from 'react';
import './ReportPage.css';
import { MapChart } from '../../../Charts/Map/MapChart';
import { DonutChart } from '../../../Charts/Donut/DonutChart';
import { BarChart } from '../../../Charts/Bar/BarChart';
import { WordCloud } from '../../../Charts/WordCloud/WordCloud';
import { AreaChart } from '../../../Charts/Area/AreaChart';
import TableChart from '../../../Charts/TableChart/TableChart';
import { saveAnalyticalMessage } from '../../../../services/api/fetchAnalyticalMessage';
import { AnalyticalMessage } from '../../../../services/api/fetchAnalyticalMessage';

export function ReportPage({ 
    title, 
    subtitle, 
    chart = { type: '', data: [] }, 
    component, 
    hideLegend, 
    pageNumber, 
    reportGrapichImage,
    isLoadingPDF,
    hasChartOptions,
    graphicsDefault,
    clientId,
    commentsAnalyticReport
}) {
    const [comment, setComment] = useState('');
    const [comments, setComments] = useState(commentsAnalyticReport || {});
    const [showInput, setShowInput] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [hasTyped, setHasTyped] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const CHARACTER_LIMIT = 500;
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const getDefaultChartType = (graphicsDefault) => {
        if (graphicsDefault === 'bar') return 'bar';
        if (graphicsDefault === 'area') return 'area';
        return 'donut';
    };
    
        const [selectedChartType, setSelectedChartType] = useState(getDefaultChartType(graphicsDefault));
    
        const handleChartTypeChange = (type) => {
            setSelectedChartType(type);
            setIsDropdownOpen(false);
        };
    
        const memoizedChart = useMemo(() => {
            switch (selectedChartType) {
                case 'donut':
                    return <DonutChart dataPoints={chart.data} />;
                case 'bar':
                    return <BarChart dataPoints={chart.data} />;
                case 'area':
                    return <AreaChart dataPoints={chart.data} />;
                case 'tabela':
                    return <TableChart dataPoints={chart.data} />;
                default:
                    return <DonutChart dataPoints={chart.data} />;
            }
        }, [selectedChartType, chart]);

    
        const memoizedDefaultChart = useMemo(() => {
            if (!chart || !chart.type) return null;
            switch (chart.type) {
                case 'donut':
                    return <DonutChart dataPoints={chart.data} />;
                case 'bar':
                    return <BarChart dataPoints={chart.data} />;
                case 'map':
                    return <MapChart data={chart.data} />;
                case 'wordcloud':
                    return <WordCloud words={chart.data} />;
                case 'area':
                    return <AreaChart dataPoints={chart.data} />;
                default:
                    return null;
            }
        }, [chart]);

        useEffect(() => {
            if (commentsAnalyticReport && Object.keys(commentsAnalyticReport).length > 0) {
                const commentForPage = commentsAnalyticReport[title] || '';
                setComment(commentForPage);
                setIsSubmitted(commentForPage.trim().length > 0);
            }
        }, [commentsAnalyticReport, title]);
        

    const saveCommentToLocalStorage = (text) => {
        localStorage.setItem(`comment-page-${pageNumber}`, text);
    };

    const handleInputChange = (event) => {
        const value = event.target.value.slice(0, CHARACTER_LIMIT);
        setComments((prev) => ({ ...prev, [title]: value }));
        setComment(value);
        setHasTyped(value.length > 0);
    };

    const handleSubmit = async () => {
        try {
            await saveAnalyticalMessage(clientId, title, comments[title]);
    
            setIsSubmitted(true);
            setSuccessMessage('Comentário salvo com sucesso!');
            setTimeout(() => setSuccessMessage(''), 5000);
        } catch (error) {
            console.error("Erro ao salvar comentário na API:", error);
            setErrorMessage('Erro ao salvar comentário.');
            setTimeout(() => setErrorMessage(''), 5000);
        }
    };

    const handleEdit = () => {
        setIsSubmitted(false);
        setHasTyped(comment.length > 0);
    };

    const handleCommentClick = () => {
        if (isSubmitted) {
            handleEdit();
        }
    };

    return (
        <div data-index={pageNumber} className='report-page'>
            <div className='report-page-block'
                style={{
                    backgroundImage: `url(${reportGrapichImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    position: 'relative',
                }}
            >
                <div className='report-page-header'>
                    <b>{title}</b>
                    {subtitle && <p>{subtitle}</p>}
                </div>

                { !isLoadingPDF && hasChartOptions  && 
                 (chart.type === 'donut' || chart.type === 'bar' || chart.type === 'area') && (
                    <div className="chart-selector-report-page">
                        <label>Escolha o tipo de gráfico:</label>
                        <div className="custom-dropdown-report-page" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                            <span>{selectedChartType === 'donut' ? 'Donut' : selectedChartType === 'bar' ? 'Barra' : 'Área'}</span>
                            <div className={`dropdown-options-report-page ${isDropdownOpen ? 'show' : ''}`}>
                                <div onClick={() => handleChartTypeChange('donut')}>Donut</div>
                                <div onClick={() => handleChartTypeChange('bar')}>Barra</div>
                                <div onClick={() => handleChartTypeChange('area')}>Área</div>
                                <div onClick={() => handleChartTypeChange('tabela')}>Tabela</div>
                            </div>
                        </div>
                    </div>
                )}

                {!component ? (
                    <div className='report-page-body'>
                    <div className='report-body-col-1'>
                    {hasChartOptions &&
                        (chart.type === 'donut' || chart.type === 'bar' || chart.type === 'area' || chart.type === 'tabela')
                            ? memoizedChart
                            : memoizedDefaultChart
                    }
                    </div>

                        <div className='report-body-col-2'>
                            <div
                                className={`${
                                    pageNumber === 14
                                        ? 'report-body-col-row-14'
                                        : 'report-body-col-row-1'
                                }`}
                            >
                                {!hideLegend &&
                                    chart.data.map((data) => (
                                        <div className='report-legend' key={data.label || data.text}>
                                            <div
                                                className='legend-square'
                                                style={{ backgroundColor: data.color }}
                                            ></div>
                                            <p>{data.y ? data.y : data.value} - </p>
                                            <p className='report-legend-label'>
                                                {data.label ? data.label : data.text}
                                            </p>
                                        </div>
                                    ))}
                            </div>

                            <div className='report-body-col-row-2'>
                                <div className='page-number'>{pageNumber}</div>
                            </div>
                        </div>

                        <div className="user-input-container" onClick={handleCommentClick}>
                            {!isSubmitted ? (
                                <>
                                    <textarea
                                        value={comment}
                                        onChange={handleInputChange}
                                        placeholder="Digite seu comentário..."
                                        rows={4}
                                        className="input-field"
                                    />
                                    {!isLoadingPDF && (
                                        <div className="char-limit-info">
                                            {`${CHARACTER_LIMIT - comment.length} caracteres restantes`}
                                        </div>
                                    )}
                                    {hasTyped && (
                                        <div className='comment-button-container'>
                                            <button className='comment-button' onClick={handleSubmit}>
                                                Salvar
                                            </button>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className="formatted-comment">
                                <p
                                    dangerouslySetInnerHTML={{
                                    __html: comment.replace(/\n/g, '<br>')
                                    }}
                                />
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className='report-page-body'>{component}</div>
                )}
            </div>

            {successMessage && (
                    <div className="success-message">
                        <p>{successMessage}</p>
                    </div>
                )}

                {errorMessage && (
                    <div className="error-message">
                        <p>{errorMessage}</p>
                    </div>
                )}
        </div>
    );
}

export default ReportPage;
