import { get } from './generic.api';

export const fetchDashboard = async (dateStart, dateEnd, clientId) => {
    try {
        const params = new URLSearchParams({
            date_start: dateStart,
            date_end: dateEnd,
            client: clientId,
        });

        const response = await get(`/dashboard/data-clippings/?${params.toString()}`);
        return response;
    } catch (error) {
        console.error('Erro ao carregar dados do dashboard:', error.response);
        throw error;
    }
};
