import React, { useRef, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import './RelatorioAnalitico.css';
import { Svg } from '../../../assets/report-icons/svg';
import { ReportPage } from './ReportPage/ReportPage';
import DonutChart from '../../Charts/Donut/DonutChart';
import BarChart from '../../Charts/Bar/BarChart';
import { saveAnalyticalMessage } from '../../../services/api/fetchAnalyticalMessage';
import { AnalyticalMessage } from '../../../services/api/fetchAnalyticalMessage';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const RelatorioAnalitico = forwardRef(({ reportData, startDate, endDate, selectedPages, isLoadingPDF, reportFinalImage, reportInitialimage, reportGrapichImage, reportInfoImage, clientId, commentsAnalytic}, ref) => {
    const [hideDivider, setHideDivider] = useState(false);
    const contentRef = useRef(ref);
    const [comments, setComments] = useState(commentsAnalytic || {});
    const [isSubmitted, setIsSubmitted] = useState({});
    const [hasTyped, setHasTyped] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [showTitle, setShowTitle] = useState(true);
    const CHARACTER_LIMIT = 500;
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const pageMappings = {
        "Análise qualitativa das matérias": "qualitativeAnalysis",
        "Notícias por tipo de texto": "textType",
        "Direta/Indireta": "directIndirect",
        "Assunto": "topicWordCloud",
        "Distribuição por mídia": "mediaDistribution",
        "Espaço ocupado na mídia": "occupiedSpace",
        "Análise qualitativa do espaço": "qualitativeSpace",
        "Notícias por veículo": "mediaNews",
        "Valoração por veículo": "mediaValue",
        "Notícias por data de publicação": "publishDate",
        "Notícias por estado": "statesNews",
        "Fator SWOT": "swot",
        "Análise de Audiência": "audienceAnalysis"
    };

    useEffect(() => {
        if (commentsAnalytic && Object.keys(commentsAnalytic).length > 0) {
            const updatedIsSubmitted = {};
            const mappedComments = Object.keys(commentsAnalytic).reduce((acc, key) => {
                const mappedKey = pageMappings[key] || key;
                acc[mappedKey] = commentsAnalytic[key];
                updatedIsSubmitted[mappedKey] = commentsAnalytic[key]?.trim().length > 0;
                return acc;
            }, {});
    
            setComments(mappedComments);
            setIsSubmitted(updatedIsSubmitted);
        }
    }, [commentsAnalytic]);
    

    const handleInputChange = (key, event) => {
        const value = event.target.value.slice(0, CHARACTER_LIMIT);
        setComments((prev) => ({ ...prev, [key]: value }));
        setHasTyped((prev) => ({ ...prev, [key]: value.length > 0 }));
    };
    const handleSubmit = async (page) => {
        try {
            const message = comments[page];
    
            await saveAnalyticalMessage(clientId, page, message);
    
            setIsSubmitted((prev) => ({ ...prev, [page]: true }));
    
            setSuccessMessage(`Comentário salvo com sucesso.`);
            setTimeout(() => setSuccessMessage(''), 5000);
    
        } catch (error) {
            console.error("Erro ao salvar comentário na API:", error);
    
            setErrorMessage('Erro ao salvar comentário. Tente novamente.');
            setTimeout(() => setErrorMessage(''), 5000);
        }
    };
    

    const handleEdit = (page) => {
        setIsSubmitted((prev) => ({
            ...prev,
            [page]: false,
        }));
        setHasTyped((prev) => ({
            ...prev,
            [page]: false,
        }));
    };

    useImperativeHandle(ref, () => ({
        handlePrint
    }));

    const isPageSelected = (pageValue) => {
        return selectedPages.some(page => page.value === pageValue);
    };
    
    const waitForAnimationEnd = (element) => {
        return new Promise((resolve) => {
            element.addEventListener('animationend', resolve, { once: true });
        });
    };

    const waitForRenderComplete = (element, timeout = 2000) => {
        return new Promise((resolve) => {
            const observer = new MutationObserver(() => {
                clearTimeout(checkTimeout);
                checkTimeout = setTimeout(() => {
                    observer.disconnect();
                    resolve();
                }, timeout);
            });
    
            let checkTimeout = setTimeout(() => {
                observer.disconnect();
                resolve();
            }, timeout);
    
            observer.observe(element, {
                attributes: true,
                childList: true,
                subtree: true
            });
        });
    };

    const processPagesInChunks = async (pages, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < pages.length; i += chunkSize) {
            const chunk = pages.slice(i, i + chunkSize);
            const processedChunk = await Promise.all(chunk.map(page => {
                const pageIndex = page.getAttribute('data-index');
                return html2canvas(page, { scale: 1.2, useCORS: true, willReadFrequently: true }).then(canvas => ({
                    canvas,
                    pageIndex: parseInt(pageIndex, 10)
                }));
            }));
            chunks.push(...processedChunk);
        }
        return chunks;
    };

    const handlePrint = async () => {
        setHideDivider(true);
        const element = contentRef.current;

        const pdf = new jsPDF({
            orientation: 'portrait',
            format: 'a4',
            compress: true,
        });

        const pageElements = Array.from(element.getElementsByClassName('report-page'));

        await waitForRenderComplete(element);
        //  Promise.all(pageElements.map(waitForAnimationEnd));

        const addPageToPDF = (canvas, pageNumber) => {
            const imgData = canvas.toDataURL('image/png');
            pageNumber > 1 && pdf.addPage();
            pdf.addImage(imgData, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
        };

        const promises = await processPagesInChunks(pageElements, 5); 

        Promise.all(promises).then(pages => {
            pages.sort((a, b) => a.pageIndex - b.pageIndex);

            pages.forEach((page, index) => {
                addPageToPDF(page.canvas, index + 1);
            });

            pdf.save('Relatorio de Análise Crítica.pdf');
            setHideDivider(false);

        });
    };

    function getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const title = "Relatório de Análise Crítica";

    const startDateFormatted = startDate ? startDate.toLocaleDateString('pt-BR') : '';
    const endDateFormatted = endDate ? endDate.toLocaleDateString('pt-BR') : '';
    const periode = `${startDateFormatted} - ${endDateFormatted}`;

    const client = reportData.client;
    const category = reportData.category;
    const totalNews = reportData.total;

    const mediaType = reportData?.source_types?.[0]?.source_type ? 
    (reportData.source_types[0].source_type.charAt(0).toUpperCase() + reportData.source_types[0].source_type.slice(1)) : 
    'N/A';

    const mediaName = reportData?.clippings_by_source?.[0]?.name || 'N/A';

    const qualitativeColors = {
        'POSITIVA': '#2c861d',
        'POSITIVO': '#2c861d',
        'Positiva': '#2c861d',
        'Positivo': '#2c861d',
        
        'NEUTRA': '#ffc20e',
        'NEUTRO': '#ffc20e',
        'Neutra': '#ffc20e',
        'Neutro': '#ffc20e',
    
        'NEGATIVA': '#ee3124',
        'NEGATIVO': '#ee3124',
        'Negativa': '#ee3124',
        'Negativo': '#ee3124'
    };
    

    const reviewQualityPage = {
        type: 'donut',
        data: reportData.qualitative.map(item => ({
            y: item.total,
            label: item.value.charAt(0) + item.value.slice(1).toLowerCase(),
            color: qualitativeColors[item.value.toUpperCase()] || getRandomColor()
        }))
    };

    const reviewTextTypePage = {
        type: 'donut',
        data: reportData.media_content_types.map(item => ({
            y: item.total,
            label: item.media_content_type || 'Indefinido',
            color: getRandomColor()
        }))
    };

    const reviewMentionPage = {
        type: 'donut',
        data: reportData.mention.map(item => ({
            y: item.total,
            label: item.value.charAt(0) + item.value.slice(1).toLowerCase(),
            color: qualitativeColors[item.value.toUpperCase()] || getRandomColor()
        }))
    };

    const reviewWordCloudPage = {
        type: 'wordcloud',
        data: reportData.tags.map(tag => ({
            value: tag.total,
            text: tag.value,
            color: getRandomColor()
        }))
    };

    const reviewMediaDistributionPage = {
        type: 'donut',
        data: reportData.source_types.map(source => ({
            y: source.total,
            label: source.source_type.charAt(0).toUpperCase() + source.source_type.slice(1),
            color: getRandomColor()
        }))
    };

    const reviewOcupedSpacePage = {
        data: [
            { type: 'TV', value: reportData.tv_occupied_space !== null ? reportData.tv_occupied_space : 0 },
            { type: 'Rádio', value: reportData.radio_occupied_space !== null ? reportData.radio_occupied_space : 0 },
            { type: 'Web', value: reportData.web_occupied_space !== null ? `${reportData.web_occupied_space} centímetros` : '0 centímetros' },
            { type: 'Impresso', value: reportData.newspaper_occupied_space !== null ? `${reportData.newspaper_occupied_space} centímetros` : '0 centímetros' }
        ]
    };

    const reviewQualityOcupedSpacePage = {
        data: [
            {
                type: 'TV',
                data: (reportData.source_qualitative?.TV || []).map(item => ({
                    y: item.y,
                    label: item.label,
                    color: qualitativeColors[item.label]
                }))
            },
            {
                type: 'WEB',
                data: (reportData.source_qualitative?.WEB || []).map(item => ({
                    y: item.y,
                    label: item.label,
                    color: qualitativeColors[item.label]
                }))
            },
            {
                type: 'Impresso',
                data: (reportData.source_qualitative?.Impresso || []).map(item => ({
                    y: item.y,
                    label: item.label,
                    color: qualitativeColors[item.label]
                }))
            },
            {
                type: 'Rádio',
                data: (reportData.source_qualitative?.Radio || []).map(item => ({
                    y: item.y,
                    label: item.label,
                    color: qualitativeColors[item.label]
                }))
            }
        ]
    };

    const reviewAudiencePage = {
        type: 'bar',
        data: reportData.sources_audience.map(item => ({
            y: item.total_audience || 0,
            label: item.name || 'Desconhecido',
            color: getRandomColor(),
        })),
    };

    const reviewMediaNewsPage = {
        type: 'bar',
        data: reportData.clippings_by_source.map(item => ({
            y: item.total,
            label: item.name,
            color: getRandomColor()
        }))
    };

    const reviewMediaValuePage = {
        type: 'bar',
        data: reportData.clippings_by_marketing_value
            .map(item => ({
                y: item.total != null ? item.total : 0, // Substituir valores nulos por 0
                label: item.source || 'Unknown', // Substituir valores nulos em source por 'Unknown'
                color: getRandomColor()
            }))
    };

    const reviewMediaPublishDataPage = {
        type: 'area',
        data: reportData.clippings_by_date
            .filter(item => item.date && item.total != null) // Filtrar itens com date e total válidos
            .map(item => ({
                x: new Date(item.date),
                y: item.total
            }))
    };

    const reviewMapPage = {
        type: 'map',
        data: reportData.states.map(item => ({
            label: item.state,
            value: item.total,
            color: getRandomColor()
        }))
    };

    const swotColors = {
        'Força': '#0000FF',       
        'Oportunidade': '#008000', 
        'Ameaça': '#FF0000',       
        'Fraqueza': '#000000'      
    };
    
    const reviewSwotPage = {
        type: 'donut',
        data: reportData.swot.map(item => ({
            y: item.total,
            label: item.value.charAt(0).toUpperCase() + item.value.slice(1).toLowerCase(),
            color: swotColors[item.value] || getRandomColor()
        }))
    };

    const initialVehiclePageNumber = reportData.swot && reportData.swot.length > 0 ? 16 : 15;

    const sumTotalMarketingValue = reportData.sum_total_marketing_value !== undefined
    ? reportData.sum_total_marketing_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    : 'R$ 0,00';

    const sumTotalAudience = reportData.sum_total_audience !== undefined
    ? reportData.sum_total_audience.toLocaleString('pt-BR')
    : '0';

    const maxRowsPerPage = 28; // Número máximo de linhas por página
    const totalPages = Math.ceil(reportData.sources.length / maxRowsPerPage);

    const getTableRowsForPage = (pageIndex) => {
        const startIndex = pageIndex * maxRowsPerPage;
        return reportData.sources.slice(startIndex, startIndex + maxRowsPerPage);
    };

    console.log('reportData', reportInitialimage)

    const getTvTableRowsForPage = (pageIndex) => {
        const startIndex = pageIndex * maxRowsPerPage;
        const tvSources = reportData.sources.filter(row => row.source_type.toLowerCase() === "tv");
        return tvSources.slice(startIndex, startIndex + maxRowsPerPage);
    };

    const getRadioTableRowsForPage = (pageIndex) => {
        const startIndex = pageIndex * maxRowsPerPage;
        const tvSources = reportData.sources.filter(row => row.source_type.toLowerCase() === "radio");
        return tvSources.slice(startIndex, startIndex + maxRowsPerPage);
    };

    const getImpressoTableRowsForPage = (pageIndex) => {
        const startIndex = pageIndex * maxRowsPerPage;
        const tvSources = reportData.sources.filter(row => row.source_type.toLowerCase() === "impresso");
        return tvSources.slice(startIndex, startIndex + maxRowsPerPage);
    };

    const getWebTableRowsForPage = (pageIndex) => {
        const startIndex = pageIndex * maxRowsPerPage;
        const webSources = reportData.sources.filter(row => 
            row.source_type.toLowerCase() === "site" || row.source_type.toLowerCase() === "blog"
        );
        return webSources.slice(startIndex, startIndex + maxRowsPerPage);
    };

    const tvSources = reportData.sources.filter(row => row.source_type && row.source_type.toLowerCase() === "tv");
    const hasTvSources = tvSources.length > 0;

    const radioSources = reportData.sources.filter(row => row.source_type && row.source_type.toLowerCase() === "radio");
    const hasRadioSources = radioSources.length > 0;

    const impressoSources = reportData.sources.filter(row => row.source_type && row.source_type.toLowerCase() === "impresso");
    const hasImpressoSources = impressoSources.length > 0;

    const webSources = reportData.sources.filter(row => 
        row.source_type && (row.source_type.toLowerCase() === "site" || row.source_type.toLowerCase() === "blog")
    );
    const hasWebSources = webSources.length > 0;

    const hasCategories = reportData.categories && reportData.categories.length > 0;
    const hasAudienceData = reportData.sources_audience && reportData.sources_audience.length > 0;

    const totalVehiclePages = Math.ceil(reportData.sources.length / maxRowsPerPage);
    const totalTvPages = Math.ceil(tvSources.length / maxRowsPerPage);
    const totalRadioPages = Math.ceil(radioSources.length / maxRowsPerPage);
    const totalImpressoPages = Math.ceil(impressoSources.length / maxRowsPerPage);
    const totalWebPages = Math.ceil(webSources.length / maxRowsPerPage);
    const totalCategoryPages = Math.ceil(reportData.categories.length / maxRowsPerPage);
    const totalTagsQualitativePages = Math.ceil(reportData.tags_qualitative.length / maxRowsPerPage);

    
    const initialTvPageNumber = initialVehiclePageNumber + totalVehiclePages;
    const initialRadioPageNumber = initialTvPageNumber + totalTvPages;
    const initialImpressoPageNumber = initialRadioPageNumber + totalRadioPages;
    const initialWebPageNumber = initialImpressoPageNumber + totalImpressoPages;
    const initialCategoriesPageNumber = initialWebPageNumber + totalWebPages;

    const calculateTotalPages = (pageValue) => {
        switch (pageValue) {
            case 'vehicles':
                return totalVehiclePages;
            case 'vehicles-tv':
                return totalTvPages;
            case 'vehicles-radio':
                return totalRadioPages;
            case 'vehicles-impresso':
                return totalImpressoPages;
            case 'vehicles-web':
                return totalWebPages;
            case 'categories':
                return totalCategoryPages;
            default:
                return 1;
        }
    };

    const calculatePageStartIndices = () => {
        let currentPage = 2;
        const pageIndices = {};
        
        selectedPages.forEach((page) => {
            pageIndices[page.value] = currentPage;
            const totalPages = calculateTotalPages(page.value);
            currentPage += totalPages;
        });
        return pageIndices;
    };
    
    const pageStartIndices = calculatePageStartIndices();
    
    const getDynamicPageNumber = (pageValue, pageIndex = 0) => {
        return pageStartIndices[pageValue] + pageIndex;
    };
    
    // Função para calcular o total de páginas por seçã

    const getSelectedPagesInOrder = () => {
        return selectedPages.map(page => page.value); // Retorna as páginas na ordem selecionada
    };

    const [coverText, setCoverText] = useState({
        title: title,
        period: periode,
        client: client,
        category: category || '' 
    });

    const toggleEditMode = () => {
        setEditMode(!editMode);
    };
    
    const toggleShowTitle = () => {
        setShowTitle(!showTitle);
    };
    
    const handleCoverInputChange = (field, value) => {
        setCoverText(prev => ({ ...prev, [field]: value }));
    };

    const getTagsQualitativeRowsForPage = (pageIndex) => {
        const startIndex = pageIndex * maxRowsPerPage;
        const endIndex = startIndex + maxRowsPerPage;
        return reportData.tags_qualitative.slice(startIndex, endIndex).map((tag) => ({
            tag: tag.tag,
            total: tag.neutral + tag.positive + tag.negative,
            positive: tag.positive,
            neutral: tag.neutral,
            negative: tag.negative
        }));
    };

    return (
        <>
            <div ref={contentRef} className='report-container'>
                <div className='report-box'>
                    <div className='report-page' data-index={1}>
                        <div className='report-page-block-initial'
                            style={{
                                height: '100%',
                                width: '100%',
                                backgroundImage: `url(${reportInitialimage})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                            }}>
                            <div className='initial-page-header'></div>
                            <div className='initial-page-text'>
                            {showTitle && (
                                <>
                                    {editMode ? (
                                        <>
                                            <input
                                                type='text'
                                                value={coverText.title}
                                                onChange={(e) => handleCoverInputChange('title', e.target.value)}
                                                className='initial-page-input'
                                                placeholder="Título"
                                            />
                                            <input
                                                type='text'
                                                value={coverText.period}
                                                onChange={(e) => handleCoverInputChange('period', e.target.value)}
                                                className='initial-page-input'
                                                placeholder="Período"
                                            />
                                            <input
                                                type='text'
                                                value={coverText.client}
                                                onChange={(e) => handleCoverInputChange('client', e.target.value)}
                                                className='initial-page-input'
                                                placeholder="Cliente"
                                            />
                                            {category && (
                                                <input
                                                    type='text'
                                                    value={coverText.category}
                                                    onChange={(e) => handleCoverInputChange('category', e.target.value)}
                                                    className='initial-page-input'
                                                    placeholder="Categoria"
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <h1 className='initial-page-h'>{coverText.title}</h1>
                                            <h4 className='initial-page-data'>{coverText.period}</h4>
                                            <h2 className='initial-page-h'>{coverText.client}</h2>
                                            {category && <h4 className='initial-page-h'>{coverText.category}</h4>}
                                        </>
                                    )}
                                </>
                            )}
                        </div>

                        {!isLoadingPDF && (
                            <div className="cover-controls">
                                {showTitle && (
                                    <button onClick={toggleEditMode}>
                                        {editMode ? 'Salvar' : 'Editar'}
                                    </button>
                                )}
                                <label className="custom-checkbox-label">
                                    <input
                                        type="checkbox"
                                        checked={showTitle}
                                        onChange={toggleShowTitle}
                                        className="custom-checkbox-input"
                                    />
                                    <span className="custom-checkbox-box"></span>
                                    Exibir Dados da Capa
                                </label>
                            </div>
                        )}
                        </div>
                    </div>

                    {!hideDivider && <div className='divider'></div>}

                    {getSelectedPagesInOrder().map((pageValue, index) => (
                    <div key={`${pageValue}-${index}`}>
                    {pageValue === 'sumary' && isPageSelected('sumary') && (
                    <div className='report-page' data-index={2}>
                        <div className='summary-header'>
                            <p id="totalNewsText">TOTAL DE NOTÍCIAS</p>
                            <p id="totalNewsNumber">{totalNews}</p>
                        </div>
                        <div className='summary-body'
                         style={{
                            height: '100%',
                            width: '100%',
                            backgroundImage: `url(${reportInfoImage})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            zIndex: 1,
                        }}>
                            <div className='summary-body-column1'>
                                <div className='summary-col-1-row-1'>
                                    <div>
                                        <p>Mídia com maior número de notícias:</p>
                                        <b>{mediaType}</b>
                                    </div>
                                </div>
                                <div className='summary-col-1-row-2'>
                                    <div>
                                        <p>Veículo com maior número de notícias:</p>
                                        <b>{mediaName}</b>
                                    </div>
                                </div>
                            </div>
                            <div className='summary-body-column2'>
                                <p>Conteúdo:</p>
                                <ul>
                                    <li>Análise qualitativa das matérias</li>
                                    <li>Notícias por tipo de texto</li>
                                    <li>Direta/Indireta</li>
                                    <li>Assunto</li>
                                    <li>Distribuição por mídia</li>
                                    <li>Espaço ocupado na mídia</li>
                                    <li>Análise qualitativa do espaço ocupado</li>
                                    <li>Notícias por veículo</li>
                                    <li>Valoração por veículo</li>
                                    <li>Notícias por data de publicação</li>
                                    <li>Notícias por estado</li>
                                </ul>
                            </div>
                        </div>
                        <div className='summary-footer'></div>
                    </div>
                     )}

                    {pageValue === 'qualitativeAnalysis' && isPageSelected('qualitativeAnalysis') && (
                    <>
                        {!hideDivider && <div className='divider'></div>}
                        <ReportPage
                            title={'Análise qualitativa das matérias'}
                            subtitle={'Volume de notícias classificadas por polaridade.'}
                            chart={reviewQualityPage}
                            pageNumber={getDynamicPageNumber('qualitativeAnalysis')}
                            reportGrapichImage={reportGrapichImage} 
                            isLoadingPDF={isLoadingPDF}
                            hasChartOptions={true}
                            graphicsDefault="donut"
                            clientId={clientId}
                            commentsAnalyticReport={commentsAnalytic}
                        />
                    </>
                )}

                    {pageValue === 'textType' && isPageSelected('textType') && (
                    <>
                        {!hideDivider && <div className='divider'></div>}
                        <ReportPage
                            title={'Notícias por tipo de texto'}
                            subtitle={'Volume de notícias classificadas por tipo de texto.'}
                            chart={reviewTextTypePage}
                            pageNumber={getDynamicPageNumber('textType')}
                            reportGrapichImage={reportGrapichImage} 
                            isLoadingPDF={isLoadingPDF}
                            hasChartOptions={true}
                            graphicsDefault="donut"
                            clientId={clientId}
                            commentsAnalyticReport={commentsAnalytic}
                        />
                    </>
                )}

                    {pageValue === 'directIndirect' && isPageSelected('directIndirect') && (
                    <>
                        {!hideDivider && <div className='divider'></div>}
                        <ReportPage
                            title={'Direta/Indireta'}
                            subtitle={'Volume de notícias por menções direta/indireta.'}
                            chart={reviewMentionPage}
                            pageNumber={getDynamicPageNumber('directIndirect')}
                            reportGrapichImage={reportGrapichImage} 
                            isLoadingPDF={isLoadingPDF}
                            hasChartOptions={true}
                            graphicsDefault="donut"
                            clientId={clientId}
                            commentsAnalyticReport={commentsAnalytic}
                        />
                    </>
                )}

                    {pageValue === 'topicWordCloud' && isPageSelected('topicWordCloud') && (
                    <>
                        {!hideDivider && <div className='divider'></div>}
                        <ReportPage
                            title={'Assunto'}
                            subtitle={'Nuvem com os assuntos mais noticiados.'}
                            chart={reviewWordCloudPage}
                            pageNumber={getDynamicPageNumber('topicWordCloud')}
                            reportGrapichImage={reportGrapichImage} 
                            isLoadingPDF={isLoadingPDF}
                            hasChartOptions={false} 
                            clientId={clientId}
                            commentsAnalyticReport={commentsAnalytic}
                        />
                    </>
                )}

                    {pageValue === 'mediaDistribution' && isPageSelected('mediaDistribution') && (
                    <>
                        {!hideDivider && <div className='divider'></div>}
                        <ReportPage
                            title={'Distribuição por mídia'}
                            subtitle={'Volume de notícias por tipo de mídia.'}
                            chart={reviewMediaDistributionPage}
                            pageNumber={getDynamicPageNumber('mediaDistribution')}
                            reportGrapichImage={reportGrapichImage} 
                            isLoadingPDF={isLoadingPDF}
                            hasChartOptions={true}
                            graphicsDefault="donut"
                            clientId={clientId}
                            commentsAnalyticReport={commentsAnalytic}
                        />
                    </>
                )}


                    {pageValue === 'occupiedSpace' && isPageSelected('occupiedSpace') && (
                    <>
                        {!hideDivider && <div className='divider'></div>}
                        <ReportPage
                            title={'Espaço ocupado na mídia'}
                            component={(
                                <>
                                    <div className='report-body-col-1'>
                                        <div className='media-distribution'>
                                            {reviewOcupedSpacePage.data.map((data) => {
                                                return (
                                                    <div key={data.type}>
                                                        <img className='icons-media' src={Svg[data.type.normalize('NFD').replace(/\p{Mn}/gu, "").toLowerCase()]} alt={data.type} />
                                                        <p className='media-type-label'>{data.type + ": "}</p> <p className='media-type-label'>{data.value}</p>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className='report-body-col-2'>
                                        <div className='report-body-col-row-1' style={{ background: 'transparent' }}></div>
                                        <div className='report-body-col-row-2'>
                                            <div className='page-number'>{getDynamicPageNumber('occupiedSpace')}</div>
                                        </div>
                                    </div>

                                    <div className="user-input-container-analitico">
                                        {!isSubmitted['occupiedSpace'] ? (
                                            <>
                                                <textarea
                                                    value={comments['occupiedSpace'] || ''}
                                                    onChange={(e) => handleInputChange('occupiedSpace', e)}
                                                    placeholder="Adicione um comentário..."
                                                    className="input-field-analitico"
                                                    rows={4}
                                                />
                                                {!isLoadingPDF && (
                                                    <div className="char-limit-info">
                                                        {`${CHARACTER_LIMIT - (comments[pageValue]?.length || 0)} caracteres restantes`}
                                                    </div>
                                                )}
                                                {hasTyped['occupiedSpace'] && (
                                                    <div className='comment-button-container-analitico'>
                                                        <button 
                                                            className="comment-button-analitico" 
                                                            onClick={() => handleSubmit('occupiedSpace')}
                                                        >
                                                            Salvar
                                                        </button>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div className="formatted-comment" onClick={() => handleEdit('occupiedSpace')}>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                __html: comments['occupiedSpace'].replace(/\n/g, '<br>')
                                                }}
                                            />
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                            pageNumber={getDynamicPageNumber('occupiedSpace')}
                            reportGrapichImage={reportGrapichImage} 
                            isLoadingPDF={isLoadingPDF}
                            hasChartOptions={false} 
                            clientId={clientId}
                            commentsAnalyticReport={commentsAnalytic}
                        />
                    </>
                )}

                    {pageValue === 'qualitativeSpace' && isPageSelected('qualitativeSpace') && (
                    <>
                        {!hideDivider && <div className='divider'></div>}
                        <ReportPage
                            title={'Análise qualitativa do espaço ocupado'}
                            subtitle={' '}
                            component={(
                                <>
                                    <div className='report-body-col-1'>
                                        <div className='space-media-container'>
                                            {reviewQualityOcupedSpacePage.data.map((chart) => {
                                                return ( chart.data.length > 0 &&
                                                    <div className='space-media' key={chart.type}>
                                                        <img className='icons-media-space' src={Svg[chart.type.normalize('NFD').replace(/\p{Mn}/gu, "").toLowerCase()]} alt={chart.type} />
                                                        <DonutChart style={{ top: '60px' }} fontSize={10} width={200} height={150} innerRadius={40} dataPoints={chart.data} hideLabel={true} />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className='report-body-col-2'>
                                        <div className='report-body-col-row-1'>
                                            {reviewQualityOcupedSpacePage.data.map((chart) => {
                                                return ( chart.data.length > 0 &&
                                                    <div key={chart.type} style={{ marginBottom: '10px' }}>
                                                        <b className='chart-type'>{chart.type}</b>
                                                        {chart.data.map((data) => {
                                                            return (
                                                                <div className='report-legend' key={data.label}>
                                                                    <div className='legend-square' style={{ backgroundColor: data.color }}></div>
                                                                    <p>{data.y} - </p>
                                                                    <p className="report-legend-label">{data.label}</p>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className='report-body-col-row-2'>
                                            <div className='page-number'>{getDynamicPageNumber('qualitativeSpace')}</div>
                                        </div>
                                    </div>

                                    <div className="user-input-container-analitico">
                                        {!isSubmitted['qualitativeSpace'] ? (
                                            <>
                                                <textarea
                                                    value={comments['qualitativeSpace'] || ''}
                                                    onChange={(e) => handleInputChange('qualitativeSpace', e)}
                                                    placeholder="Adicione um comentário..."
                                                    className="input-field-analitico"
                                                    rows={4}
                                                />
                                                {!isLoadingPDF && (
                                                    <div className="char-limit-info">
                                                        {`${CHARACTER_LIMIT - (comments[pageValue]?.length || 0)} caracteres restantes`}
                                                    </div>
                                                )}
                                                {hasTyped['qualitativeSpace'] && (
                                                    <div className='comment-button-container-analitico'>
                                                        <button 
                                                            className="comment-button-analitico" 
                                                            onClick={() => handleSubmit('qualitativeSpace')}
                                                        >
                                                            Salvar
                                                        </button>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div className="formatted-comment" onClick={() => handleEdit('qualitativeSpace')}>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                __html: comments['qualitativeSpace'].replace(/\n/g, '<br>')
                                                }}
                                            />
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                            pageNumber={getDynamicPageNumber('qualitativeSpace')}
                            reportGrapichImage={reportGrapichImage} 
                            isLoadingPDF={isLoadingPDF}
                            hasChartOptions={false} 
                            clientId={clientId}
                            commentsAnalyticReport={commentsAnalytic}
                        />
                    </>
                )}

                    {pageValue === 'mediaNews' && isPageSelected('mediaNews') && (
                    <>
                        {!hideDivider && <div className='divider'></div>}
                        <ReportPage
                            title={'Notícias por veículo'}
                            subtitle={'Ranking com 10 veículos de imprensa que mais noticiaram.'}
                            chart={reviewMediaNewsPage}
                            pageNumber={getDynamicPageNumber('mediaNews')}
                            reportGrapichImage={reportGrapichImage} 
                            isLoadingPDF={isLoadingPDF}
                            hasChartOptions={true}
                            graphicsDefault="bar"
                            clientId={clientId}
                            commentsAnalyticReport={commentsAnalytic}
                        />
                    </>
                )}

                    {pageValue === 'mediaValue' && isPageSelected('mediaValue') && (
                    <>
                        {!hideDivider && <div className='divider'></div>}
                        <ReportPage
                            title={'Valoração por veículo'}
                            subtitle={'Ranking com 10 veículos de imprensa com maior valor publicitário.'}
                            chart={reviewMediaValuePage}
                            component={(
                                <div className='report-page-body'>
                                    <div className='report-body-col-1'>
                                        <BarChart dataPoints={reviewMediaValuePage.data.filter(item => item.y > 0)} />
                                        <div className='total-media-values'>
                                        <p>{'Total: '}<b>{reviewMediaValuePage.data.reduce((total, item) => {
                                            return total + item.y;
                                            }, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b></p>
                                            <p>{'Soma Total de Valoração: '}<b>{sumTotalMarketingValue}</b></p>
                                    </div>
                                    </div>
                                    <div className='report-body-col-2'>
                                        <div className='report-body-col-row-1'>
                                            {reviewMediaValuePage.data.filter(item => item.y > 0).map((data) => {
                                                return (
                                                    <div className='report-legend' key={data.label}>
                                                        <div className='legend-square' style={{ backgroundColor: data.color }}></div>
                                                        <p>{data.y.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                                    </div>
                                                );
                                            })}
                                            
                                        </div>
                                        <div className='report-body-col-row-2'>
                                            <div className='page-number'>{getDynamicPageNumber('mediaValue')}</div>
                                        </div>
                                    </div>

                                    <div className="user-input-container-analitico">
                                        {!isSubmitted['mediaValue'] ? (
                                            <>
                                                <textarea
                                                    value={comments['mediaValue'] || ''}
                                                    onChange={(e) => handleInputChange('mediaValue', e)}
                                                    placeholder="Adicione um comentário..."
                                                    className="input-field-analitico"
                                                    rows={4}
                                                />
                                                {!isLoadingPDF && (
                                                    <div className="char-limit-info">
                                                        {`${CHARACTER_LIMIT - (comments[pageValue]?.length || 0)} caracteres restantes`}
                                                    </div>
                                                )}
                                                {hasTyped['mediaValue'] && (
                                                    <div className='comment-button-container-analitico'>
                                                    <button 
                                                        className="comment-button-analitico" 
                                                        onClick={() => handleSubmit('mediaValue')}
                                                    >
                                                        Salvar
                                                    </button>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div className="formatted-comment" onClick={() => handleEdit('mediaValue')}>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                __html: comments['mediaValue'].replace(/\n/g, '<br>')
                                                }}
                                            />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            pageNumber={getDynamicPageNumber('mediaValue')}
                            reportGrapichImage={reportGrapichImage} 
                            isLoadingPDF={isLoadingPDF}
                            hasChartOptions={true}
                            graphicsDefault="bar"
                            clientId={clientId}
                            commentsAnalyticReport={commentsAnalytic}
                        />
                    </>
                )}

                    {pageValue === 'publishDate' && isPageSelected('publishDate') && (
                    <>
                        {!hideDivider && <div className='divider'></div>}
                        <ReportPage
                            title={'Notícias por data de publicação'}
                            subtitle={'Volume de notícias por data de publicação.'}
                            chart={reviewMediaPublishDataPage}
                            hideLegend={true}
                            pageNumber={getDynamicPageNumber('publishDate')}
                            reportGrapichImage={reportGrapichImage} 
                            isLoadingPDF={isLoadingPDF}
                            hasChartOptions={true}
                            graphicsDefault="area"
                            clientId={clientId}
                            commentsAnalyticReport={commentsAnalytic}
                        />
                    </>
                )}

                    {pageValue === 'statesNews' && isPageSelected('statesNews') && (
                    <>
                        {!hideDivider && <div className='divider'></div>}
                        <ReportPage
                            title={'Notícias por estado'}
                            subtitle={'Volume de notícias classificadas por estado.'}
                            chart={reviewMapPage}
                            pageNumber={getDynamicPageNumber('statesNews')}
                            reportGrapichImage={reportGrapichImage} 
                            isLoadingPDF={isLoadingPDF}
                            hasChartOptions={false} 
                            clientId={clientId}
                            commentsAnalyticReport={commentsAnalytic}
                        />
                    </>
                )}

                    {pageValue === 'swot' && isPageSelected('swot') && reportData.swot && reportData.swot.length > 0 && (
                        <>
                            {!hideDivider && <div className='divider'></div>}
                            <ReportPage
                                title={'Fator SWOT'}
                                chart={reviewSwotPage}
                                pageNumber={getDynamicPageNumber('swot')}
                                reportGrapichImage={reportGrapichImage} 
                                isLoadingPDF={isLoadingPDF}
                                hasChartOptions={true}
                                graphicsDefault="donut"
                                clientId={clientId}
                                commentsAnalyticReport={commentsAnalytic}
                            />
                        </>
                    )}

                    {pageValue === 'audienceAnalysis' && isPageSelected('audienceAnalysis') && hasAudienceData && (
                        <>
                            {!hideDivider && <div className='divider'></div>}
                            <ReportPage
                                title={'Análise de Audiência por Veículo'}
                                subtitle={'Ranking com os 10 veículos e suas respectivas audiências totais.'}
                                chart={reviewAudiencePage}
                                component={(
                                    <div className='report-page-body'>
                                        <div className='report-body-col-1'>
                                            <BarChart dataPoints={reviewAudiencePage.data.filter(item => item.y > 0)} />
                                            <div className='total-media-values'>
                                                <p>{'Total: '}<b>{reviewAudiencePage.data.reduce((total, item) => {
                                                    return total + item.y;
                                                }, 0).toLocaleString('pt-BR')}</b></p>
                                                <p>{'Soma Total de Audiência: '}<b>{sumTotalAudience}</b></p>
                                            </div>
                                        </div>
                                        <div className='report-body-col-2'>
                                            <div className='report-body-col-row-1'>
                                                {reviewAudiencePage.data.filter(item => item.y > 0).map((data) => {
                                                    return (
                                                        <div className='report-legend' key={data.label}>
                                                            <div className='legend-square' style={{ backgroundColor: data.color }}></div>
                                                            <p>{data.y.toLocaleString('pt-BR')}</p>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <div className='report-body-col-row-2'>
                                                <div className='page-number'>{getDynamicPageNumber('audienceAnalysis')}</div>
                                            </div>
                                        </div>

                                        <div className="user-input-container-analitico">
                                            {!isSubmitted['audienceAnalysis'] ? (
                                                <>
                                                    <textarea
                                                        value={comments['audienceAnalysis'] || ''}
                                                        onChange={(e) => handleInputChange('audienceAnalysis', e)}
                                                        placeholder="Adicione um comentário..."
                                                        className="input-field-analitico"
                                                        rows={4}
                                                    />
                                                    {!isLoadingPDF && (
                                                        <div className="char-limit-info">
                                                            {`${CHARACTER_LIMIT - (comments['audienceAnalysis']?.length || 0)} caracteres restantes`}
                                                        </div>
                                                    )}
                                                    {hasTyped['audienceAnalysis'] && (
                                                        <div className='comment-button-container-analitico'>
                                                            <button 
                                                                className="comment-button-analitico" 
                                                                onClick={() => handleSubmit('audienceAnalysis')}
                                                            >
                                                                Salvar
                                                            </button>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <div className="formatted-comment" onClick={() => handleEdit('audienceAnalysis')}>
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: comments['audienceAnalysis'].replace(/\n/g, '<br>')
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                pageNumber={getDynamicPageNumber('audienceAnalysis')}
                                reportGrapichImage={reportGrapichImage}
                                isLoadingPDF={isLoadingPDF}
                                hasChartOptions={true}
                                graphicsDefault="bar"
                                clientId={clientId}
                                commentsAnalyticReport={commentsAnalytic}
                            />
                        </>
                    )}

                    {!hideDivider && <div className='divider'></div>}

                    {pageValue === 'totalMedia' && isPageSelected('totalMedia') && (
                        <>
                            {!hideDivider && <div className='divider'></div>}
                            <ReportPage
                                title="Total de Mídias"
                                component={(
                                    <>
                                    <div className='report-table-container'>
                                        <table className='report-table'>
                                            <thead>
                                                <tr>
                                                    <th className='table-header-veiculos'>Veículos</th>
                                                    <th className='table-header-dados'>Total</th>
                                                    <th className='table-header-dados'>Positiva</th>
                                                    <th className='table-header-dados'>Neutra</th>
                                                    <th className='table-header-dados'>Negativa</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.entries(reportData.source_qualitative).map(([mediaType, data], index) => {
                                                    const total = data.reduce((sum, item) => sum + item.y, 0);
                                                    const positive = data.find(item =>
                                                        ['positiva', 'positivo'].includes(item.label.toLowerCase())
                                                    )?.y || 0;
                                                    
                                                    const neutral = data.find(item =>
                                                        ['neutra', 'neutro'].includes(item.label.toLowerCase())
                                                    )?.y || 0;
                                                    
                                                    const negative = data.find(item =>
                                                        ['negativa', 'negativo'].includes(item.label.toLowerCase())
                                                    )?.y || 0;

                                                    return (
                                                        <tr key={index}>
                                                            <td>{mediaType}</td>
                                                            <td>{total}</td>
                                                            <td>{positive}</td>
                                                            <td>{neutral}</td>
                                                            <td>{negative}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                        <div className='container-number'>
                                            <div className='page-number'>{getDynamicPageNumber('totalMedia')}</div>
                                        </div>
                                    </div>
                                        <div className="user-input-container-analitico">
                                            {!isSubmitted['totalMedia'] ? (
                                                <>
                                                    <textarea
                                                        value={comments['totalMedia'] || ''}
                                                        onChange={(e) => handleInputChange('totalMedia', e)}
                                                        placeholder="Adicione um comentário..."
                                                        className="input-field-analitico"
                                                        rows={4}
                                                    />
                                                    {!isLoadingPDF && (
                                                        <div className="char-limit-info">
                                                            {`${CHARACTER_LIMIT - (comments[pageValue]?.length || 0)} caracteres restantes`}
                                                        </div>
                                                    )}
                                                    {hasTyped['totalMedia'] && (
                                                        <div className='comment-button-container-analitico'>
                                                            <button 
                                                                className="comment-button-analitico" 
                                                                onClick={() => handleSubmit('totalMedia')}
                                                            >
                                                                Salvar
                                                            </button>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <div className="formatted-comment" onClick={() => handleEdit('totalMedia')}>
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: comments['totalMedia'].replace(/\n/g, '<br>')
                                                        }}
                                                    />
                                                </div>
                                            )}
                                    </div>
                                </>
                                )}
                                pageNumber={getDynamicPageNumber('totalMedia')}
                                reportGrapichImage={reportGrapichImage}
                                hasChartOptions={false} 
                                clientId={clientId}
                                commentsAnalyticReport={commentsAnalytic}
                            />
                        </>
                    )}

                    {pageValue === 'vehicles' && isPageSelected('vehicles') && Array.from({ length: totalVehiclePages  }).map((_, pageIndex) => {
                          const isLastPage = pageIndex === totalVehiclePages - 1;
                        return (
                            <>
                                {!hideDivider && <div className='divider'></div>}
                                <ReportPage
                                    key={pageIndex}
                                    title={`Veículos`}
                                    component={(
                                        <>
                                        <div className='report-table-container'>
                                            <table className='report-table'>
                                                <thead>
                                                    <tr>
                                                        <th className='table-header-veiculos'>Veículos</th>
                                                        <th className='table-header-dados'>Total</th>
                                                        <th className='table-header-dados'>Positiva</th>
                                                        <th className='table-header-dados'>Neutra</th>
                                                        <th className='table-header-dados'>Negativa</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getTableRowsForPage(pageIndex).map((row, index) => (
                                                        <tr key={index}>
                                                            <td>{row.name}</td>
                                                            <td>{row.positive + row.neutral + row.negative}</td>
                                                            <td>{row.positive}</td>
                                                            <td>{row.neutral}</td>
                                                            <td>{row.negative}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div className='container-number'>
                                                <div className='page-number'>{getDynamicPageNumber('vehicles', pageIndex)}</div>
                                            </div>
                                        </div>
                                            {isLastPage && (
                                                <div className="user-input-container-analitico">
                                                    {!isSubmitted['vehicles'] ? (
                                                        <>
                                                            <textarea
                                                                value={comments['vehicles'] || ''}
                                                                onChange={(e) => handleInputChange('vehicles', e)}
                                                                placeholder="Adicione um comentário..."
                                                                className="input-field-analitico"
                                                                rows={4}
                                                            />
                                                            {!isLoadingPDF && (
                                                                <div className="char-limit-info">
                                                                    {`${CHARACTER_LIMIT - (comments[pageValue]?.length || 0)} caracteres restantes`}
                                                                </div>
                                                            )}
                                                            {hasTyped['vehicles'] && (
                                                                <div className='comment-button-container-analitico'>
                                                                    <button
                                                                        className="comment-button-analitico"
                                                                        onClick={() => handleSubmit('vehicles')}
                                                                    >
                                                                        Salvar
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <div className="formatted-comment" onClick={() => handleEdit('vehicles')}>
                                                            <p
                                                                dangerouslySetInnerHTML={{
                                                                    __html: comments['vehicles'].replace(/\n/g, '<br>')
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    )}
                                    pageNumber={getDynamicPageNumber('vehicles', pageIndex)}
                                    reportGrapichImage={reportGrapichImage} 
                                    hasChartOptions={false} 
                                    clientId={clientId}
                                    commentsAnalyticReport={commentsAnalytic}
                                />
                            </>
                        );
                    })}

                    {pageValue === 'vehicles-tv' && isPageSelected('vehicles-tv') && hasTvSources && Array.from({ length: Math.ceil(reportData.sources.filter(row => row.source_type.toLowerCase() === "tv").length / maxRowsPerPage) }).map((_, pageIndex) => {
                        const isLastPage = pageIndex === totalTvPages - 1;
                        return (
                            <>
                                {!hideDivider && <div className='divider'></div>}
                                <ReportPage
                                    key={pageIndex}
                                    title="Veículos de TV"
                                    component={(
                                        <>
                                        <div className='report-table-container'>
                                            <table className='report-table'>
                                                <thead>
                                                    <tr>
                                                        <th className='table-header-veiculos'>Veículos</th>
                                                        <th className='table-header-dados'>Total</th>
                                                        <th className='table-header-dados'>Positiva</th>
                                                        <th className='table-header-dados'>Neutra</th>
                                                        <th className='table-header-dados'>Negativa</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getTvTableRowsForPage(pageIndex).map((row, index) => (
                                                        <tr key={index}>
                                                            <td>{row.name}</td>
                                                            <td>{row.positive + row.neutral + row.negative}</td>
                                                            <td>{row.positive}</td>
                                                            <td>{row.neutral}</td>
                                                            <td>{row.negative}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div className='container-number'>
                                                <div className='page-number'>{getDynamicPageNumber('vehicles-tv', pageIndex)}</div>
                                            </div>
                                        </div>
                                        {isLastPage && (
                                                <div className="user-input-container-analitico">
                                                    {!isSubmitted['vehicles-tv'] ? (
                                                        <>
                                                            <textarea
                                                                value={comments['vehicles-tv'] || ''}
                                                                onChange={(e) => handleInputChange('vehicles-tv', e)}
                                                                placeholder="Adicione um comentário..."
                                                                className="input-field-analitico"
                                                                rows={4}
                                                            />
                                                            {!isLoadingPDF && (
                                                                <div className="char-limit-info">
                                                                    {`${CHARACTER_LIMIT - (comments[pageValue]?.length || 0)} caracteres restantes`}
                                                                </div>
                                                            )}
                                                            {hasTyped['vehicles-tv'] && (
                                                                <div className='comment-button-container-analitico'>
                                                                    <button
                                                                        className="comment-button-analitico"
                                                                        onClick={() => handleSubmit('vehicles-tv')}
                                                                    >
                                                                        Salvar
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <div className="formatted-comment" onClick={() => handleEdit('vehicles-tv')}>
                                                            <p
                                                                dangerouslySetInnerHTML={{
                                                                    __html: comments['vehicles-tv'].replace(/\n/g, '<br>')
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    )}
                                    pageNumber={getDynamicPageNumber('vehicles-tv', pageIndex)}
                                    reportGrapichImage={reportGrapichImage} 
                                    hasChartOptions={false} 
                                    clientId={clientId}
                                    commentsAnalyticReport={commentsAnalytic}
                                />
                            </>
                        );
                    })}

                    {pageValue === 'vehicles-radio' && isPageSelected('vehicles-radio') && hasRadioSources && Array.from({ length: Math.ceil(reportData.sources.filter(row => row.source_type.toLowerCase() === "radio").length / maxRowsPerPage) }).map((_, pageIndex) => {
                        const isLastPage = pageIndex === totalRadioPages - 1;
                        return (
                            <>
                                {!hideDivider && <div className='divider'></div>}
                                <ReportPage
                                    key={pageIndex}
                                    title="Veículos de Rádio"
                                    component={(
                                        <>
                                        <div className='report-table-container'>
                                            <table className='report-table'>
                                                <thead>
                                                    <tr>
                                                        <th className='table-header-veiculos'>Veículos</th>
                                                        <th className='table-header-dados'>Total</th>
                                                        <th className='table-header-dados'>Positiva</th>
                                                        <th className='table-header-dados'>Neutra</th>
                                                        <th className='table-header-dados'>Negativa</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getRadioTableRowsForPage(pageIndex).map((row, index) => (
                                                        <tr key={index}>
                                                            <td>{row.name}</td>
                                                            <td>{row.positive + row.neutral + row.negative}</td>
                                                            <td>{row.positive}</td>
                                                            <td>{row.neutral}</td>
                                                            <td>{row.negative}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div className='container-number'>
                                                <div className='page-number'>{getDynamicPageNumber('vehicles-radio' , pageIndex) }</div>
                                            </div>
                                        </div>
                                        {isLastPage && (
                                                <div className="user-input-container-analitico">
                                                    {!isSubmitted['vehicles-radio'] ? (
                                                        <>
                                                            <textarea
                                                                value={comments['vehicles-radio'] || ''}
                                                                onChange={(e) => handleInputChange('vehicles-radio', e)}
                                                                placeholder="Adicione um comentário..."
                                                                className="input-field-analitico"
                                                                rows={4}
                                                            />
                                                            {!isLoadingPDF && (
                                                                <div className="char-limit-info">
                                                                    {`${CHARACTER_LIMIT - (comments[pageValue]?.length || 0)} caracteres restantes`}
                                                                </div>
                                                            )}
                                                            {hasTyped['vehicles-radio'] && (
                                                                <div className='comment-button-container-analitico'>
                                                                    <button
                                                                        className="comment-button-analitico"
                                                                        onClick={() => handleSubmit('vehicles-radio')}
                                                                    >
                                                                        Salvar
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <div className="formatted-comment" onClick={() => handleEdit('vehicles-radio')}>
                                                            <p
                                                                dangerouslySetInnerHTML={{
                                                                    __html: comments['vehicles-radio'].replace(/\n/g, '<br>')
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    )}
                                    pageNumber={getDynamicPageNumber('vehicles-radio' , pageIndex)}
                                    reportGrapichImage={reportGrapichImage} 
                                    hasChartOptions={false} 
                                    clientId={clientId}
                                    commentsAnalyticReport={commentsAnalytic}
                                />
                            </>
                        );
                    })}

                    {pageValue === 'vehicles-impresso' && isPageSelected('vehicles-impresso') && hasImpressoSources && Array.from({ length: Math.ceil(reportData.sources.filter(row => row.source_type.toLowerCase() === "impresso").length / maxRowsPerPage) }).map((_, pageIndex) => {
                        const isLastPage = pageIndex === totalImpressoPages - 1;
                        return (
                            <>
                                {!hideDivider && <div className='divider'></div>}
                                <ReportPage
                                    key={pageIndex}
                                    title="Veículos de Impresso"
                                    component={(
                                        <>
                                        <div className='report-table-container'>
                                            <table className='report-table'>
                                                <thead>
                                                    <tr>
                                                        <th className='table-header-veiculos'>Veículos</th>
                                                        <th className='table-header-dados'>Total</th>
                                                        <th className='table-header-dados'>Positiva</th>
                                                        <th className='table-header-dados'>Neutra</th>
                                                        <th className='table-header-dados'>Negativa</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getImpressoTableRowsForPage(pageIndex).map((row, index) => (
                                                        <tr key={index}>
                                                            <td>{row.name}</td>
                                                            <td>{row.positive + row.neutral + row.negative}</td>
                                                            <td>{row.positive}</td>
                                                            <td>{row.neutral}</td>
                                                            <td>{row.negative}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div className='container-number'>
                                                <div className='page-number'>{getDynamicPageNumber('vehicles-impresso', pageIndex)}</div>
                                            </div>
                                        </div>
                                        {isLastPage && (
                                                <div className="user-input-container-analitico">
                                                    {!isSubmitted['vehicles-impresso'] ? (
                                                        <>
                                                            <textarea
                                                                value={comments['vehicles-impresso'] || ''}
                                                                onChange={(e) => handleInputChange('vehicles-impresso', e)}
                                                                placeholder="Adicione um comentário..."
                                                                className="input-field-analitico"
                                                                rows={4}
                                                            />
                                                            {!isLoadingPDF && (
                                                                <div className="char-limit-info">
                                                                    {`${CHARACTER_LIMIT - (comments[pageValue]?.length || 0)} caracteres restantes`}
                                                                </div>
                                                            )}
                                                            {hasTyped['vehicles-impresso'] && (
                                                                <div className='comment-button-container-analitico'>
                                                                    <button
                                                                        className="comment-button-analitico"
                                                                        onClick={() => handleSubmit('vehicles-impresso')}
                                                                    >
                                                                        Salvar
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <div className="formatted-comment" onClick={() => handleEdit('vehicles-impresso')}>
                                                            <p
                                                                dangerouslySetInnerHTML={{
                                                                    __html: comments['vehicles-impresso'].replace(/\n/g, '<br>')
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    )}
                                    pageNumber={getDynamicPageNumber('vehicles-impresso', pageIndex)}
                                    reportGrapichImage={reportGrapichImage} 
                                    hasChartOptions={false} 
                                    clientId={clientId}
                                    commentsAnalyticReport={commentsAnalytic}
                                />
                            </>
                        );
                    })}

                    {pageValue === 'vehicles-web' && isPageSelected('vehicles-web') && hasWebSources  && Array.from({ length: Math.ceil(reportData.sources.filter(row => row.source_type.toLowerCase() === "site" || row.source_type.toLowerCase() === "blog").length / maxRowsPerPage) }).map((_, pageIndex) => {
                        const isLastPage = pageIndex === totalWebPages - 1;
                        return (
                            <>
                                {!hideDivider && <div className='divider'></div>}
                                <ReportPage
                                    key={pageIndex}
                                    title="Veículos Web"
                                    component={(
                                        <>
                                        <div className='report-table-container'>
                                            <table className='report-table'>
                                                <thead>
                                                    <tr>
                                                        <th className='table-header-veiculos'>Veículos</th>
                                                        <th className='table-header-dados'>Total</th>
                                                        <th className='table-header-dados'>Positiva</th>
                                                        <th className='table-header-dados'>Neutra</th>
                                                        <th className='table-header-dados'>Negativa</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getWebTableRowsForPage(pageIndex).map((row, index) => (
                                                        <tr key={index}>
                                                            <td>{row.name}</td>
                                                            <td>{row.positive + row.neutral + row.negative}</td>
                                                            <td>{row.positive}</td>
                                                            <td>{row.neutral}</td>
                                                            <td>{row.negative}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div className='container-number'>
                                                <div className='page-number'>{getDynamicPageNumber('vehicles-web', pageIndex)}</div>
                                            </div>
                                        </div>
                                        {isLastPage && (
                                                <div className="user-input-container-analitico">
                                                    {!isSubmitted['vehicles-web'] ? (
                                                        <>
                                                            <textarea
                                                                value={comments['vehicles-web'] || ''}
                                                                onChange={(e) => handleInputChange('vehicles-web', e)}
                                                                placeholder="Adicione um comentário..."
                                                                className="input-field-analitico"
                                                                rows={4}
                                                            />
                                                            {!isLoadingPDF && (
                                                                <div className="char-limit-info">
                                                                    {`${CHARACTER_LIMIT - (comments[pageValue]?.length || 0)} caracteres restantes`}
                                                                </div>
                                                            )}
                                                            {hasTyped['vehicles-web'] && (
                                                                <div className='comment-button-container-analitico'>
                                                                    <button
                                                                        className="comment-button-analitico"
                                                                        onClick={() => handleSubmit('vehicles-web')}
                                                                    >
                                                                        Salvar
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <div className="formatted-comment" onClick={() => handleEdit('vehicles-web')}>
                                                            <p
                                                                dangerouslySetInnerHTML={{
                                                                    __html: comments['vehicles-web'].replace(/\n/g, '<br>')
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    )}
                                    pageNumber={getDynamicPageNumber('vehicles-web', pageIndex)}
                                    reportGrapichImage={reportGrapichImage} 
                                    hasChartOptions={false} 
                                    clientId={clientId}
                                    commentsAnalyticReport={commentsAnalytic}
                                />
                            </>
                        );
                    })}

                    {pageValue === 'categories' && isPageSelected('categories')  && hasCategories && Array.from({ length: totalCategoryPages }).map((_, pageIndex) => {
                        const isLastPage = pageIndex === totalCategoryPages - 1;
                        return (
                            <>
                                {!hideDivider && <div className='divider'></div>}
                                <ReportPage
                                    key={pageIndex}
                                    title="Categorias"
                                    component={(
                                        <>
                                        <div className='report-table-container'>
                                            <table className='report-table'>
                                                <thead>
                                                    <tr>
                                                        <th className='table-header-categorias'>Categoria</th>
                                                        <th className='table-header-dados'>Total</th>
                                                        <th className='table-header-dados'>Positiva</th>
                                                        <th className='table-header-dados'>Neutra</th>
                                                        <th className='table-header-dados'>Negativa</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {reportData.categories.slice(pageIndex * maxRowsPerPage, (pageIndex + 1) * maxRowsPerPage).map((category, index) => (
                                                        <tr key={index}>
                                                            <td>{category.name}</td>
                                                            <td>{category.positive + category.neutral + category.negative}</td>
                                                            <td>{category.positive}</td>
                                                            <td>{category.neutral}</td>
                                                            <td>{category.negative}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div className='container-number'>
                                                <div className='page-number'>{getDynamicPageNumber('categories', pageIndex)}</div>
                                            </div>
                                        </div>
                                        {isLastPage && (
                                                <div className="user-input-container-analitico">
                                                    {!isSubmitted['categories'] ? (
                                                        <>
                                                            <textarea
                                                                value={comments['categories'] || ''}
                                                                onChange={(e) => handleInputChange('categories', e)}
                                                                placeholder="Adicione um comentário..."
                                                                className="input-field-analitico"
                                                                rows={4}
                                                            />
                                                            {!isLoadingPDF && (
                                                                <div className="char-limit-info">
                                                                    {`${CHARACTER_LIMIT - (comments[pageValue]?.length || 0)} caracteres restantes`}
                                                                </div>
                                                            )}
                                                            {hasTyped['categories'] && (
                                                                <div className='comment-button-container-analitico'>
                                                                    <button
                                                                        className="comment-button-analitico"
                                                                        onClick={() => handleSubmit('categories')}
                                                                    >
                                                                        Salvar
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <div className="formatted-comment" onClick={() => handleEdit('categories')}>
                                                            <p
                                                                dangerouslySetInnerHTML={{
                                                                    __html: comments['categories'].replace(/\n/g, '<br>')
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    )}
                                    pageNumber={getDynamicPageNumber('categories', pageIndex)}
                                    reportGrapichImage={reportGrapichImage}
                                    hasChartOptions={false} 
                                    clientId={clientId}
                                    commentsAnalyticReport={commentsAnalytic}
                                />
                            </>
                        );
                    })}

{pageValue === 'tagsQualitative' && isPageSelected('tagsQualitative') && Array.from({ length: totalTagsQualitativePages }).map((_, pageIndex) => {
    const isLastPage = pageIndex === totalTagsQualitativePages - 1;
    return (
        <>
            {!hideDivider && <div className='divider'></div>}
            <ReportPage
                key={pageIndex}
                title="Assuntos"
                component={(
                    <>
                        <div className='report-table-container'>
                            <table className='report-table'>
                                <thead>
                                    <tr>
                                        <th className='table-header-tags'>Tag</th>
                                        <th className='table-header-dados'>Total</th>
                                        <th className='table-header-dados'>Positiva</th>
                                        <th className='table-header-dados'>Neutra</th>
                                        <th className='table-header-dados'>Negativa</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getTagsQualitativeRowsForPage(pageIndex).map((row, index) => (
                                        <tr key={index}>
                                            <td>{row.tag}</td>
                                            <td>{row.total}</td>
                                            <td>{row.positive}</td>
                                            <td>{row.neutral}</td>
                                            <td>{row.negative}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className='container-number'>
                                <div className='page-number'>{getDynamicPageNumber('tagsQualitative', pageIndex)}</div>
                            </div>
                        </div>
                        {isLastPage && (
                            <div className="user-input-container-analitico">
                                {!isSubmitted['tagsQualitative'] ? (
                                    <>
                                        <textarea
                                            value={comments['tagsQualitative'] || ''}
                                            onChange={(e) => handleInputChange('tagsQualitative', e)}
                                            placeholder="Adicione um comentário..."
                                            className="input-field-analitico"
                                            rows={4}
                                        />
                                        {!isLoadingPDF && (
                                            <div className="char-limit-info">
                                                {`${CHARACTER_LIMIT - (comments['tagsQualitative']?.length || 0)} caracteres restantes`}
                                            </div>
                                        )}
                                        {hasTyped['tagsQualitative'] && (
                                            <div className='comment-button-container-analitico'>
                                                <button
                                                    className="comment-button-analitico"
                                                    onClick={() => handleSubmit('tagsQualitative')}
                                                >
                                                    Salvar
                                                </button>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div className="formatted-comment" onClick={() => handleEdit('tagsQualitative')}>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: comments['tagsQualitative'].replace(/\n/g, '<br>')
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}
                pageNumber={getDynamicPageNumber('tagsQualitative', pageIndex)}
                reportGrapichImage={reportGrapichImage}
                hasChartOptions={false} 
                clientId={clientId}
                commentsAnalyticReport={commentsAnalytic}
            />
        </>
    );
})}

                    {!hideDivider && <div className='divider'></div>}

                    {pageValue === 'Final' && isPageSelected('Final') && reportFinalImage && (

                    <div className='report-page' data-index={getDynamicPageNumber('Final')}>
                        <div className='report-page-block-final'
                            style={{
                                height: '100%',
                                width: '100%',
                                backgroundImage: `url(${reportFinalImage})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                            }}>
                        </div>
                    </div>

                )}
                </div>
))}

{successMessage && (
                    <div className="success-message">
                        <p>{successMessage}</p>
                    </div>
                )}

                {errorMessage && (
                    <div className="error-message">
                        <p>{errorMessage}</p>
                    </div>
                )}
                </div>
            </div>
        </>
    );
});

export default RelatorioAnalitico;
