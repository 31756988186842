import React, { useState, useEffect, useRef } from 'react';
import './TelaVisualizacaoPublica.css';
import { useParams } from 'react-router-dom';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import { fetchPublicClippingById } from '../../services/publicApi/PublicApi';
import { FaDownload, FaFileAlt, FaLink, FaFile, FaImage } from 'react-icons/fa';
import ReactLoading from 'react-loading';
import MP4Player from '../../components/VideoPlayer/MP4Player';

function TelaVisualizacaoPublica() {
  const [showA4Modal, setShowA4Modal] = useState(false);
  const { clippingId } = useParams();
  const [clipping, setClipping] = useState(null);
  const [activeThumbnailIndex, setActiveThumbnailIndex] = useState(0);
  const [currentThumbnailIndex, setCurrentThumbnailIndex] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomOrigin, setZoomOrigin] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [videoCardStyle, setVideoCardStyle] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [showResume, setShowResume] = useState(true);
  const [showOriginalPagesModal, setShowOriginalPagesModal] = useState(false);
  const [currentOriginalPageIndex, setCurrentOriginalPageIndex] = useState(0);
  const [isOriginalPagesZoomed, setIsOriginalPagesZoomed] = useState(false);
  const [originalPagesZoomOrigin, setOriginalPagesZoomOrigin] = useState({ x: 0, y: 0 });
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // useEffect(() => {
  //   const handleResize = () => {
  //     let newStyle = {};
  
  //     if (clipping && (clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio')) {
  //       if (window.innerWidth < 321) {
  //         newStyle = {
  //           width: '230px',
  //           display: 'flex',
  //           justifyContent: 'center',
  //           height: 'auto',
  //           marginLeft: '-120px',
  //         };
  //       } else if (window.innerWidth < 361) {
  //         newStyle = {
  //           width: '270px',
  //           display: 'flex',
  //           justifyContent: 'center',
  //           height: 'auto',
  //           marginLeft: '-135px',
  //         };
  //       } else if (window.innerWidth < 391) {
  //         newStyle = {
  //           width: '280px',
  //           display: 'flex',
  //           justifyContent: 'center',
  //           height: 'auto',
  //           marginLeft: '-140px',
  //         };
  //       } else if (window.innerWidth < 481) {
  //         newStyle = {
  //           width: '285px',
  //           display: 'flex',
  //           justifyContent: 'center',
  //           height: 'auto',
  //           marginLeft: '-135px',
  //         };
  //       } else if (window.innerWidth < 779) {
  //         newStyle = {
  //           width: '565px',
  //           display: 'flex',
  //           justifyContent: 'center',
  //           height: 'auto',
  //           marginLeft: '-220px',
  //         };
  //       } else if (window.innerWidth < 801) {
  //         newStyle = {
  //           width: '590px',
  //           display: 'flex',
  //           justifyContent: 'center',
  //           height: 'auto',
  //           marginLeft: '-230px',
  //         };
  //       } else if (window.innerWidth < 821) {
  //         newStyle = {
  //           width: '610px',
  //           display: 'flex',
  //           justifyContent: 'center',
  //           height: 'auto',
  //           marginLeft: '-240px',
  //         };
  //       } else if (window.innerWidth < 835) {
  //         newStyle = {
  //           width: '625px',
  //           display: 'flex',
  //           justifyContent: 'center',
  //           height: 'auto',
  //           marginLeft: '-245px',
  //         };
  //       } else if (window.innerWidth < 1025) {
  //         newStyle = {
  //           width: '815px',
  //           display: 'flex',
  //           justifyContent: 'center',
  //           height: 'auto',
  //           marginLeft: '-312px',
  //         };
  //       } else if (window.innerWidth < 1600) {
  //         newStyle = {
  //           width: '400px',
  //           display: 'flex',
  //           justifyContent: 'end',
  //           height: 'auto',
  //           marginLeft: '-120px',
  //         };
  //       } else if (window.innerWidth < 1750) {
  //         newStyle = {
  //           width: '500px',
  //           display: 'flex',
  //           justifyContent: 'end',
  //           height: 'auto',
  //           marginLeft: '-120px',
  //         };
  //       } else {
  //         newStyle = {
  //           width: '700px',
  //           display: 'flex',
  //           justifyContent: 'end',
  //           height: 'auto',
  //           marginLeft: '-290px',
  //         };
  //       }
  //     }
  
  //     setVideoCardStyle(newStyle);
  //   };
  
  //   handleResize();
  //   window.addEventListener('resize', handleResize);
  
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, [clipping]);
  
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(`Fetching clipping with ID: ${clippingId}`);
        const foundClipping = await fetchPublicClippingById(clippingId); // Busca o clipping pelo ID
        console.log('Clipping encontrado:', foundClipping);
        setClipping(foundClipping);
      } catch (error) {
        console.error("Error fetching data:", error);
        setErrorMessage('Erro ao buscar o clipping. Por favor, tente novamente mais tarde.');
      } finally {
        setLoading(false); 
      }
    };

    fetchData();
  }, [clippingId]);

  useEffect(() => {
    if (clipping && clipping.thumbnails && clipping.thumbnails.length > 0) {
      const interval = setInterval(() => {
        setActiveThumbnailIndex(prevIndex => (prevIndex + 1) % clipping.thumbnails.length);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [clipping]);

  useEffect(() => {
    if (clipping && clipping.source_detail && 
        (clipping.source_detail.source_type === 'tv' || 
         clipping.source_detail.source_type === 'radio') && 
        clipping.files && clipping.files.length > 0) {
      
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) =>
          (prevIndex + 1) % clipping.files.length
        );
      }, 10000);
  
      return () => clearInterval(interval);
    }
  }, [clipping]);

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      (prevIndex + 1) % clipping.files.length
    );
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      (prevIndex - 1 + clipping.files.length) % clipping.files.length
    );
  };

  const highlightKeywordsInHTML = (html, keywordsList) => {
    if (!keywordsList || keywordsList.length === 0) return html;
    const escapedKeywords = keywordsList.map(k => k.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')); // Escapar caracteres especiais
    const regex = new RegExp(`\\b(${escapedKeywords.join('|')})\\b`, 'gi');
    return html.replace(regex, '<span class="highlight">$1</span>');
  };

  if (loading) {
    return <p>Carregando...</p>;
  }

  if (!clipping) {
    return <p>Clipping não encontrado.</p>;
  }

  const isRestrictedType = clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio';
  const hasA4Image = (clipping.source_detail.source_type === 'blog' || clipping.source_detail.source_type === 'site') 
    ? clipping.files && clipping.files.length > 0 
    : (clipping.source_detail.source_type !== 'tv' && clipping.source_detail.source_type !== 'radio');

    const baseUrl = process.env.REACT_APP_API_URL.replace(/\/$/, '');
    const url_video = clipping.stream_url 
    ? `${baseUrl}${clipping.stream_url}`
    : clipping.files && clipping.files.length > 0 
      ? clipping.files[0].content_file
      : null;

  let videoTitleContent;
  if (clipping.source_detail.source_type === 'radio') {
    videoTitleContent = 'Áudio do Clipping:';
  } else if (clipping.source_detail.source_type === 'tv') {
    videoTitleContent = 'Vídeo do Clipping:';
  } else {
    videoTitleContent = 'Arquivo do Clipping:';
  }

  const handleOpenA4Modal = () => {
    if ((clipping.source_detail.source_type === 'blog' || clipping.source_detail.source_type === 'site') && clipping.files && clipping.files.length > 0) {
      setCurrentThumbnailIndex(0);
      setIsZoomed(false);
      setZoomOrigin({ x: 0, y: 0 });
    }
    setShowA4Modal(true);
  };

  // Realizar download do arquivo de TV e Radio
  const handleDownload = async (url) => {
    setIsDownloadLoading(true);
    setSuccessMessage('');
    setErrorMessage('');
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Erro ao baixar o arquivo');
      }
      const blob = await response.blob();
      const link = document.createElement('a');
      const downloadUrl = window.URL.createObjectURL(blob);
      const filename = `clipping${getFileExtension(url)}`; // Nome do arquivo com a extensão correta
      link.href = downloadUrl;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
      setSuccessMessage('Download concluído com sucesso!');
      setTimeout(() => setSuccessMessage(''), 5000);
    } catch (error) {
      console.error('Erro ao baixar o arquivo:', error);
      setErrorMessage('Erro ao baixar o arquivo. Por favor, tente novamente.');
      setTimeout(() => setErrorMessage(''), 5000);
    } finally {
      setIsDownloadLoading(false);
    }
  };
  
  const getFileExtension = (url) => {
    const extension = url.split('.').pop().split(/\#|\?/)[0];
    return extension ? `.${extension}` : '';
  };

  const handleCloseA4Modal = () => {
    setShowA4Modal(false);
  };

  const handleNextThumbnail = () => {
    setCurrentThumbnailIndex((prevIndex) => (prevIndex + 1) % clipping.thumbnails.length);
    setIsZoomed(false);
    setZoomOrigin({ x: 0, y: 0 });
  };

  const handlePrevThumbnail = () => {
    setCurrentThumbnailIndex((prevIndex) => (prevIndex - 1 + clipping.thumbnails.length) % clipping.thumbnails.length);
    setIsZoomed(false);
    setZoomOrigin({ x: 0, y: 0 });
  };

  const handleImageClick = (e) => {
    const { offsetX, offsetY, target } = e.nativeEvent;
    const { width, height } = target;
    const zoomX = ((offsetX / width) * 100).toFixed(2);
    const zoomY = ((offsetY / height) * 100).toFixed(2);

    setZoomOrigin({ x: zoomX, y: zoomY });
    setIsZoomed((prevZoom) => !prevZoom);
  };

  const clippingCardStyle = isRestrictedType ? (
    window.innerWidth < 321 ? { width: '230px', marginLeft: '-83px' } :
    window.innerWidth < 361 ? { width: '265px', marginLeft: '-85px' } :
    window.innerWidth < 391 ? { width: '275px', marginLeft: '-80px' } :
    window.innerWidth < 481 ? { width: '290px', marginLeft: '-75px' } :
    window.innerWidth < 779 ? { width: '565px', marginLeft: '-75px' } :
    window.innerWidth < 801 ? { width: '590px', marginLeft: '-75px' } :
    window.innerWidth < 821 ? { width: '605px', marginLeft: '-80px' } :
    window.innerWidth < 835 ? { width: '625px', marginLeft: '-85px' } :
    window.innerWidth < 1025 ? { width: '815px', marginLeft: '-105px' } :
    { width: '530px' }
  ) : {};

  const adjustImageSizesInHTML = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    
    doc.querySelectorAll('img').forEach(img => {
      img.style.width = '500px';
      img.style.height = '300px';
    });
    
    return doc.body.innerHTML;
  };

  const highlightedContent = highlightKeywordsInHTML(clipping.content, clipping.keyword_list);
  const adjustedHighlightedContent = adjustImageSizesInHTML(highlightedContent);
  const titleWithSource = `${clipping.title} (Fonte: ${clipping.source_detail.name})`;
  const highlightedTitleWithSource = highlightKeywordsInHTML(titleWithSource, clipping.keyword_list);

  const showMarketingValue = (clipping.marketing_value);
  const showOccupiedSpace = (clipping.occupied_space);
  const showProgramValue = (clipping.program && clipping.program.name && (clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio'));
  const ShowMediaType = (clipping.media_content_type);

  let startSeconds, endSeconds;
  if (clipping.source_detail && (clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio')) {
    startSeconds = clipping.audiovisual_clip ? clipping.audiovisual_clip.start_as_total_seconds : null;
    endSeconds = clipping.audiovisual_clip ? clipping.audiovisual_clip.end_as_total_seconds : null;
  }

  const toggleResumeContent = () => {
    setShowResume((prevShowResume) => !prevShowResume);
  };

  const handleOpenOriginalPagesModal = () => {
    setCurrentOriginalPageIndex(0);
    setShowOriginalPagesModal(true);
  };
  
  const handleCloseOriginalPagesModal = () => {
    setShowOriginalPagesModal(false);
  };
  
  const handleNextOriginalPage = () => {
    setCurrentOriginalPageIndex((prevIndex) => (prevIndex + 1) % clipping.original_pages.length);
  };
  
  const handlePrevOriginalPage = () => {
    setCurrentOriginalPageIndex((prevIndex) => (prevIndex - 1 + clipping.original_pages.length) % clipping.original_pages.length);
  };

  const handleOriginalPageImageClick = (e) => {
    const { offsetX, offsetY, target } = e.nativeEvent;
    const { width, height } = target;
    const zoomX = ((offsetX / width) * 100).toFixed(2);
    const zoomY = ((offsetY / height) * 100).toFixed(2);
  
    setOriginalPagesZoomOrigin({ x: zoomX, y: zoomY });
    setIsOriginalPagesZoomed((prevZoom) => !prevZoom);
  };

  const handleNextFile = () => {
    if (clipping.files && clipping.files.length > 1) {
      setCurrentFileIndex((prevIndex) => (prevIndex + 1) % clipping.files.length);
      setIsZoomed(false);
      setZoomOrigin({ x: 0, y: 0 });
    }
  };
  
  const handlePrevFile = () => {
    if (clipping.files && clipping.files.length > 1) {
      setCurrentFileIndex((prevIndex) => (prevIndex - 1 + clipping.files.length) % clipping.files.length);
      setIsZoomed(false);
      setZoomOrigin({ x: 0, y: 0 });
    }
  };

  const handleOpenFilesModal = () => {
    setCurrentThumbnailIndex(0);
    setIsZoomed(false);
    setZoomOrigin({ x: 0, y: 0 });
    setShowA4Modal(true); // Reutilizando o modal A4
  };
  

  return (
    <div className="App">
      <div className="clipping-container-publico">
        <header className="clipping-header-public">
          {/* <h1 className="clipping-title">
            <span className="clipping-title-text">{clipping.title}</span>
          </h1> */}
          <div className="clipping-header-buttons-public">
              {clipping.source_detail.source_type !== 'tv' && clipping.source_detail.source_type !== 'radio' && (
                <>
                  <button 
                    className={`clipping-action-button-public ${hasA4Image ? '' : 'disabled'}`} 
                    onClick={hasA4Image ? handleOpenA4Modal : undefined}
                    disabled={!hasA4Image}
                    style={{ cursor: hasA4Image ? 'pointer' : 'not-allowed' }}
                  >
                    <FaFileAlt />
                    <span className="a4-text">Visualizar A4</span>
                  </button>
                  {clipping.source_detail.source_type === 'impresso' && clipping.original_pages && clipping.original_pages.length > 0 && (
                    <button
                      className="clipping-action-button"
                      onClick={handleOpenOriginalPagesModal}
                    >
                      <FaFile  />
                      <span className="a4-text">Página Original</span>
                    </button>
                  )}
                  {clipping.url && (
                    <a 
                      href={clipping.url} 
                      className={`clipping-action-link-public ${clipping.url ? '' : 'disabled'}`} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      style={{ cursor: clipping.url ? 'pointer' : 'not-allowed', pointerEvents: clipping.url ? 'auto' : 'none' }}
                    >
                      <FaLink />
                      <span className="link-text">Link da notícia</span>
                    </a>
                  )}
                </>
              )}
              {(clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio') && 
                  clipping.files && clipping.files.length > 0 && (
                      <button 
                        className="clipping-action-button-public" 
                        onClick={handleOpenFilesModal}
                        style={{ cursor: 'pointer' }}
                      >
                        <FaImage />
                        <span className="a4-text">Visualizar Imagens</span>
                      </button>
                )}
            </div>
        </header>

        {isLoading && (
        <div className={isLoading ? 'loading-overlay active' : 'loading-overlay'}>
          <ReactLoading type={'spin'} color={'#121926'} height={80} width={80} />
        </div>
      )}

      {successMessage && <div className="success-message">{successMessage}</div>}
      {errorMessage && <div className="error-message">{errorMessage}</div>}

      {clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio' ? (
        <div className="clipping-additional-info">
          <div className="clipping-title-container">
            <h2 className="clipping-additional-title">{clipping.title}</h2>
            <hr className="clipping-additional-divider" />
          </div>
          <div className="clipping-details-container">
            <p>Tipo do clipping: {clipping.source_detail.source_type}</p>
            {ShowMediaType && <p>Tipo de Notícia: {clipping.media_content_type}</p>}
            <p>Data da publicação: {clipping.publish_date}</p>
            <p>Fonte: {clipping.source_detail.name}</p>
            <p>Categoria: {clipping.categories.length > 0 ? clipping.categories.map(category => category.name).join(', ') : 'N/A'}</p>
            {showProgramValue && <p>Programa: {clipping.program.name}</p>}
            {showMarketingValue && <p>Valoração: R$ {clipping.marketing_value}</p>}
            {showOccupiedSpace && (
              <p>
                {clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio'
                  ? 'Minutagem'
                  : 'Centimetragem'}: {clipping.occupied_space}
              </p>
            )}
          </div>
        </div>
      ) : null}

      {clipping.stream_url && (
        <div className="clipping-content">
          <div className="clipping-row">
            {clipping.source_detail.source_type !== 'site' && 
              clipping.source_detail.source_type !== 'blog' && 
            <aside className="clipping-video-public">
              {(clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio') && (
              <div className="clipping-video-card-public" style={videoCardStyle}>
              <div className="clipping-video-header">
                <h3 className="clipping-video-title">{videoTitleContent}</h3>
                {(clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio') && clipping.document && (
                  <button onClick={() => handleDownload(clipping.document)} className="download-button-public" disabled={isDownloadLoading}>
                  {isDownloadLoading ? (
                    <span className="loading-spinner"></span>
                  ) : (
                    <FaDownload />
                  )}
                </button>
                )}
              </div>
              {isRestrictedType ? (
                    clipping.stream_url ? (
                      <VideoPlayer
                        link={url_video}
                      />
                    ) : (
                      clipping.files && clipping.files.length > 0 && clipping.files[0].content_file && (
                        <MP4Player
                          link={url_video}
                          startSeconds={startSeconds !== undefined ? startSeconds : null}
                          endSeconds={endSeconds !== undefined ? endSeconds : null}
                        />
                      )
                    )
                  ): (
                  /*clipping.thumbnails && clipping.thumbnails.length > 0 ? (
                    <div className="clipping-thumbnails-carousel">
                      {clipping.thumbnails.map((thumbnail, index) => (
                        <img 
                          key={index} 
                          src={thumbnail} 
                          alt={`Thumbnail ${index + 1}`} 
                          className={`clipping-thumbnail ${index === activeThumbnailIndex ? 'active' : ''}`} 
                        />
                      ))}
                    </div>
                  ) :*/ ''
                  /*(
                    (clipping.source_detail.source_type === 'site' || clipping.source_detail.source_type === 'blog') && (
                      <iframe
                        src={clipping.url}
                        title="Pré-visualização do site"
                        width="100%"
                        height="400px"
                        frameBorder="0"
                        style={{ border: '1px solid #ccc' }}
                      ></iframe>
                    )
                  )*/
                )}
              </div>
              )}
            </aside>}
          </div>
        </div>
      )}

        {clipping && clipping.files && clipping.files.length > 0 && (clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio') &&  (
          <div className="clipping-content-carrosel-visualization-screen-public">
            <div className="clipping-row-carrosel-visualization-screen-public">
                <aside className="clipping-images-carousel-public">
                  <div className="carousel-container-public">
                    <button className='prev-button-visualization-screen-public' onClick={handlePrevImage}>&lt;</button>
                    
                    <img
                      src={clipping.files[currentImageIndex].content_file}
                      alt={`Imagem ${currentImageIndex + 1}`}
                      className="carousel-image-public"
                    />

                    <button className='prev-button-visualization-screen-public' onClick={handleNextImage}>&gt;</button>
                  </div>
                </aside>
            </div>
          </div>
        )}

        <article className="clipping-description">
        <div className="clipping-content-toggle">
          {clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio' ? (
            <>
              <button 
                onClick={() => setShowResume(true)} 
                className={showResume ? 'selected' : ''}
              >
                Resumo
              </button>
              <button 
                onClick={() => setShowResume(false)} 
                className={!showResume ? 'selected' : ''}
              >
                Transcrição
              </button>
            </>
          ) : null}
        </div>
          <div className="clipping-description-content-wrapper">
            {clipping.source_detail.source_type === 'radio' || clipping.source_detail.source_type === 'tv' ? (
             <div className="clipping-description-text">
             {clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio' ? (
               showResume ? (
                 <p>{clipping.resume}</p>
               ) : (
                 <div dangerouslySetInnerHTML={{ __html: adjustedHighlightedContent }}></div>
               )
             ) : (
               <div dangerouslySetInnerHTML={{ __html: adjustedHighlightedContent }}></div>
             )}
           </div>
            ) : (
              <div 
                className="clipping-text-content" 
                style={{ width: clipping.source_detail.source_type === 'impresso' ? '60%' : '100%' }}
              >
                <h2 className="clipping-description-title" dangerouslySetInnerHTML={{ __html: highlightedTitleWithSource }}></h2>
                <p className="clipping-detail-inline">
                  Tipo do clipping: {clipping.source_detail.source_type} <br  />
                  {clipping.media_content_type && (
                    <p style={{ margin: 0 }}>Tipo de Notícia: {clipping.media_content_type}</p>
                  )}
                  Data da publicação: {clipping.publish_date} <br  />
                  Categoria: {clipping.categories.length > 0 ? clipping.categories.map(category => category.name).join(', ') : 'N/A'} <br  />
                  Fonte: {clipping.source_detail.name} 
                  {showProgramValue && ` Programa: ${clipping.program.name} `} <br  />
                  {showMarketingValue && ` Valoração: R$ ${clipping.marketing_value} `} <br  />
                  {showOccupiedSpace && `${clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio' ? ' Minutagem' : ' Centimetragem'}: ${clipping.occupied_space}`}
                </p>
                <hr className="clipping-description-divider" />
                <div className="clipping-description-text" dangerouslySetInnerHTML={{ __html: adjustedHighlightedContent }}></div>
              </div>
            )}

            {clipping.source_detail.source_type === 'impresso' && clipping.thumbnails && clipping.thumbnails.length > 0 && (
              <div className="clipping-thumbnails-carousel">
                {clipping.thumbnails.map((thumbnail, index) => (
                  <img 
                    key={index} 
                    src={thumbnail} 
                    alt={`Thumbnail ${index + 1}`} 
                    className={`clipping-thumbnail ${index === activeThumbnailIndex ? 'active' : ''}`} 
                  />
                ))}
              </div>
            )}
          </div>
        </article>

        {showOriginalPagesModal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <button className="close-modal" onClick={handleCloseOriginalPagesModal}>X</button>
              <div className="thumbnail-navigation">
                <button onClick={handlePrevOriginalPage} disabled={clipping.original_pages.length <= 1}>&#8592;</button>
                <button onClick={handleNextOriginalPage} disabled={clipping.original_pages.length <= 1}>&#8594;</button>
              </div>
              <div className="thumbnail-image-container" ref={imageRef}>
                <img 
                  src={clipping.original_pages[currentOriginalPageIndex]} 
                  alt={`Original Page ${currentOriginalPageIndex + 1}`} 
                  className="thumbnail-image"
                  onClick={handleOriginalPageImageClick}
                  style={{ 
                    transformOrigin: `${originalPagesZoomOrigin.x}% ${originalPagesZoomOrigin.y}%`,
                    transform: `scale(${isOriginalPagesZoomed ? 2 : 1})`
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {showA4Modal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <button className="close-modal" onClick={handleCloseA4Modal}>X</button>
              <div className="thumbnail-navigation">
                  {clipping.source_detail.source_type === 'impresso' ? (
                    <>
                      <button 
                        onClick={handlePrevThumbnail} 
                        disabled={clipping.thumbnails.length <= 1}
                      >
                        &#8592;
                      </button>
                      <button 
                        onClick={handleNextThumbnail} 
                        disabled={clipping.thumbnails.length <= 1}
                      >
                        &#8594;
                      </button>
                    </>
                  ) : (
                    <>
                      <button 
                        onClick={handlePrevFile} 
                        disabled={clipping.files.length <= 1}
                      >
                        &#8592;
                      </button>
                      <button 
                        onClick={handleNextFile} 
                        disabled={clipping.files.length <= 1}
                      >
                        &#8594;
                      </button>
                    </>
                  )}
                </div>
              <div 
                className="thumbnail-image-container"
                ref={imageRef}
              >
            {clipping.source_detail.source_type === 'blog' || clipping.source_detail.source_type === 'site' || clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio'? (
                  <img 
                  src={clipping.files && clipping.files[currentFileIndex] ? clipping.files[currentFileIndex].content_file : ''} 
                  alt={`Content File ${currentFileIndex + 1}`} 
                    className="thumbnail-image" 
                    onClick={handleImageClick}
                    style={{ 
                      transformOrigin: `${zoomOrigin.x}% ${zoomOrigin.y}%`,
                      transform: `scale(${isZoomed ? 2 : 1})`
                    }}
                  />
                ) : (
                  <img 
                    src={clipping.thumbnails[currentThumbnailIndex]} 
                    alt={`Thumbnail ${currentThumbnailIndex + 1}`} 
                    className="thumbnail-image" 
                    onClick={handleImageClick}
                    style={{ 
                      transformOrigin: `${zoomOrigin.x}% ${zoomOrigin.y}%`,
                      transform: `scale(${isZoomed ? 2 : 1})`
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )}

      {clipping.related_news && clipping.related_news.length > 0 && (
                <div className="related-news-section">
                  <ul className="related-news-list">
                    {clipping.related_news.map((news) => (
                      <li key={news.id} className="related-news-item">
                        <p className="related-news-source">
                          {news.source_detail?.name || 'Fonte desconhecida'} - {news.publish_date}
                        </p>
                        <a
                          href={`${process.env.REACT_APP_CLIENT_URL}visualizarNoticiaNewsletter/${news.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="related-news-title"
                        >
                          {news.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
      </div>
    </div>
  );
}

export default TelaVisualizacaoPublica;