import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';

const VideoPlayer = ({ link, isModalOpen }) => {
  const videoRef = useRef(null);
  const hlsInstance = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    console.log('isModalOpen',isModalOpen)

    if (Hls.isSupported()) {
      const hls = new Hls();
      hlsInstance.current = hls;
      hls.loadSource(link);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play().catch(error => {
          console.error("Erro ao reproduzir o vídeo automaticamente:", error);
        });
      });
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = link;
      video.addEventListener('loadedmetadata', () => {
        video.play().catch(error => {
          console.error("Erro ao reproduzir o vídeo automaticamente:", error);
        });
      });
    }

    return () => {
      if (hlsInstance.current) {
        hlsInstance.current.destroy();
      }
      if (video) {
        video.pause();
        video.src = '';
      }
    };
  }, [link]);

  useEffect(() => {
    if (isModalOpen !== undefined && !isModalOpen && videoRef.current) {
      videoRef.current.pause();
    }
  }, [isModalOpen]);

  return (
    <div className="video-container">
      <video
        ref={videoRef}
        controls
        width="100%"
        height="auto"
        playsInline
        webkit-playsinline="true"
      />
    </div>
  );
};

export default VideoPlayer;
