import { post, del, get } from './generic.api';

export const saveAnalyticalMessage = async (clientId, page, message) => {
    try {
      const response = await post('/analytical-report-message/', {
            client: clientId, 
            page: page, 
            message: message
      });
      return response;
    } catch (error) {
      console.error('Erro ao carregar mensagens do analitico:', error.response);
      throw error;
    }
  };

  export const AnalyticalMessage = async (clientId) => {
    try {
      const response = await get('/analytical-report-message/', {
            client: clientId,
      });
      return response.results;
    } catch (error) {
      console.error('Erro ao carregar mensagens do analitico:', error.response);
      throw error;
    }
  };
